"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/ethereum-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GasEstimateToJSON = exports.GasEstimateFromJSONTyped = exports.GasEstimateFromJSON = exports.instanceOfGasEstimate = void 0;
/**
 * Check if a given object implements the GasEstimate interface.
 */
function instanceOfGasEstimate(value) {
    let isInstance = true;
    isInstance = isInstance && "gasLimit" in value;
    return isInstance;
}
exports.instanceOfGasEstimate = instanceOfGasEstimate;
function GasEstimateFromJSON(json) {
    return GasEstimateFromJSONTyped(json, false);
}
exports.GasEstimateFromJSON = GasEstimateFromJSON;
function GasEstimateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gasLimit': json['gasLimit'],
    };
}
exports.GasEstimateFromJSONTyped = GasEstimateFromJSONTyped;
function GasEstimateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gasLimit': value.gasLimit,
    };
}
exports.GasEstimateToJSON = GasEstimateToJSON;
