"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenBalanceChangeToJSON = exports.TokenBalanceChangeFromJSONTyped = exports.TokenBalanceChangeFromJSON = exports.instanceOfTokenBalanceChange = void 0;
const RawTokenAmount_1 = require("./RawTokenAmount");
/**
 * Check if a given object implements the TokenBalanceChange interface.
 */
function instanceOfTokenBalanceChange(value) {
    let isInstance = true;
    isInstance = isInstance && "userAccount" in value;
    isInstance = isInstance && "tokenAccount" in value;
    isInstance = isInstance && "rawTokenAmount" in value;
    isInstance = isInstance && "mint" in value;
    return isInstance;
}
exports.instanceOfTokenBalanceChange = instanceOfTokenBalanceChange;
function TokenBalanceChangeFromJSON(json) {
    return TokenBalanceChangeFromJSONTyped(json, false);
}
exports.TokenBalanceChangeFromJSON = TokenBalanceChangeFromJSON;
function TokenBalanceChangeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userAccount': json['userAccount'],
        'tokenAccount': json['tokenAccount'],
        'rawTokenAmount': (0, RawTokenAmount_1.RawTokenAmountFromJSON)(json['rawTokenAmount']),
        'mint': json['mint'],
    };
}
exports.TokenBalanceChangeFromJSONTyped = TokenBalanceChangeFromJSONTyped;
function TokenBalanceChangeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userAccount': value.userAccount,
        'tokenAccount': value.tokenAccount,
        'rawTokenAmount': (0, RawTokenAmount_1.RawTokenAmountToJSON)(value.rawTokenAmount),
        'mint': value.mint,
    };
}
exports.TokenBalanceChangeToJSON = TokenBalanceChangeToJSON;
