"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstructionToJSON = exports.InstructionFromJSONTyped = exports.InstructionFromJSON = exports.instanceOfInstruction = void 0;
const InnerInstruction_1 = require("./InnerInstruction");
/**
 * Check if a given object implements the Instruction interface.
 */
function instanceOfInstruction(value) {
    let isInstance = true;
    isInstance = isInstance && "innerInstructions" in value;
    isInstance = isInstance && "programId" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "accounts" in value;
    return isInstance;
}
exports.instanceOfInstruction = instanceOfInstruction;
function InstructionFromJSON(json) {
    return InstructionFromJSONTyped(json, false);
}
exports.InstructionFromJSON = InstructionFromJSON;
function InstructionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'innerInstructions': (json['innerInstructions'].map(InnerInstruction_1.InnerInstructionFromJSON)),
        'programId': json['programId'],
        'data': json['data'],
        'accounts': json['accounts'],
    };
}
exports.InstructionFromJSONTyped = InstructionFromJSONTyped;
function InstructionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'innerInstructions': (value.innerInstructions.map(InnerInstruction_1.InnerInstructionToJSON)),
        'programId': value.programId,
        'data': value.data,
        'accounts': value.accounts,
    };
}
exports.InstructionToJSON = InstructionToJSON;
