"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const ethers_1 = require("ethers");
const _1 = require(".");
class Parser {
    constructor(args) {
        this.abiInterface = new ethers_1.ethers.Interface(contracts_1.RFOX_ABI);
        this.supportedFunctions = {
            stake: this.abiInterface.getFunction('stake').selector,
            unstake: this.abiInterface.getFunction('unstake').selector,
            withdrawClaim: this.abiInterface.getFunction('withdraw(uint256)').selector,
            withdraw: this.abiInterface.getFunction('withdraw()').selector,
            setRuneAddress: this.abiInterface.getFunction('setRuneAddress').selector,
        };
        this.proxyContract = args.proxyContract;
        this.stakingAssetId = args.stakingAssetId;
    }
    async parse(tx) {
        if (!(0, _1.txInteractsWithContract)(tx, this.proxyContract))
            return;
        if (!tx.inputData)
            return;
        const txSigHash = (0, _1.getSigHash)(tx.inputData);
        if (!Object.values(this.supportedFunctions).some(hash => hash === txSigHash))
            return;
        const decoded = this.abiInterface.parseTransaction({ data: tx.inputData });
        if (!decoded)
            return;
        const data = {
            method: decoded.name,
            parser: 'rfox',
            type: 'evm',
            assetId: this.stakingAssetId,
        };
        switch (txSigHash) {
            case this.supportedFunctions.unstake:
                const amount = decoded.args.amount;
                return await Promise.resolve({
                    data: { ...data, method: `${data.method}Request`, value: amount.toString() },
                });
            case this.supportedFunctions.stake:
            case this.supportedFunctions.setRuneAddress:
                const runeAddress = decoded.args.runeAddress;
                return await Promise.resolve({ data: { ...data, runeAddress } });
            case this.supportedFunctions.withdrawClaim:
                const index = decoded.args.index;
                return await Promise.resolve({ data: { ...data, claimIndex: Number(index) } });
            default:
                return await Promise.resolve({ data });
        }
    }
}
exports.Parser = Parser;
