"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterEvmAssetIdsBySellable = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
function filterEvmAssetIdsBySellable(assets) {
    return assets.filter(asset => {
        const { chainId } = asset;
        // evm only with no arbitrum nova support for any swappers
        return (0, chain_adapters_1.isEvmChainId)(chainId) && chainId !== caip_1.arbitrumNovaChainId;
    });
}
exports.filterEvmAssetIdsBySellable = filterEvmAssetIdsBySellable;
