"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseThorBuyTxHash = void 0;
const THORCHAIN_EVM_CHAINS = ['ETH', 'AVAX', 'BSC'];
const parseThorBuyTxHash = (sellTxId, latestOutTx) => {
    if (!latestOutTx)
        return;
    // outbound rune transactions do not have a txid as they are processed internally, use sell txid
    if (latestOutTx.chain === 'THOR')
        return sellTxId;
    const isEvmCoinAsset = THORCHAIN_EVM_CHAINS.some(chain => chain === latestOutTx.chain);
    return isEvmCoinAsset ? `0x${latestOutTx.id}` : latestOutTx.id;
};
exports.parseThorBuyTxHash = parseThorBuyTxHash;
