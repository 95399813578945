"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const types_1 = require("../../../types");
const parser_1 = require("../../parser");
class Parser {
    async parse(tx) {
        if (!(0, parser_1.txInteractsWithContract)(tx, contracts_1.COWSWAP_SETTLEMENT_CONTRACT_MAINNET))
            return;
        if (!(tx.tokenTransfers && tx.tokenTransfers.length))
            return;
        return await Promise.resolve({
            trade: {
                dexName: types_1.Dex.CowSwap,
                type: types_1.TradeType.Trade,
            },
            data: {
                method: undefined,
                parser: 'cowswap',
            },
        });
    }
}
exports.Parser = Parser;
