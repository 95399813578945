"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/arbitrum-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTypeToJSON = exports.TokenTypeFromJSONTyped = exports.TokenTypeFromJSON = exports.TokenType = void 0;
/**
 * Supported token types for token metadata
 * @export
 */
exports.TokenType = {
    Erc721: 'erc721',
    Erc1155: 'erc1155'
};
function TokenTypeFromJSON(json) {
    return TokenTypeFromJSONTyped(json, false);
}
exports.TokenTypeFromJSON = TokenTypeFromJSON;
function TokenTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TokenTypeFromJSONTyped = TokenTypeFromJSONTyped;
function TokenTypeToJSON(value) {
    return value;
}
exports.TokenTypeToJSON = TokenTypeToJSON;
