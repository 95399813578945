"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WBNB_TOKEN = exports.WAVAX_TOKEN = exports.WETH_TOKEN = exports.DEFAULT_STREAMING_INTERVAL = exports.DEFAULT_STREAMING_NUM_SWAPS = exports.LIMIT_PART_DELIMITER = exports.POOL_PART_DELIMITER = exports.MEMO_PART_DELIMITER = exports.THORCHAIN_AFFILIATE_NAME = exports.THORCHAIN_FIXED_PRECISION = exports.THOR_EVM_GAS_LIMIT = exports.THOR_MINIMUM_PADDING = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const sdk_core_1 = require("@uniswap/sdk-core");
exports.THOR_MINIMUM_PADDING = 1.2;
exports.THOR_EVM_GAS_LIMIT = '100000'; // for sends of eth / erc20 into thorchain router
exports.THORCHAIN_FIXED_PRECISION = 8; // limit values are precision 8 regardless of the chain
exports.THORCHAIN_AFFILIATE_NAME = 'ss';
exports.MEMO_PART_DELIMITER = ':';
exports.POOL_PART_DELIMITER = '.';
exports.LIMIT_PART_DELIMITER = '/';
exports.DEFAULT_STREAMING_NUM_SWAPS = 0; // let network decide, see https://dev.thorchain.org/thorchain-dev/concepts/streaming-swaps
exports.DEFAULT_STREAMING_INTERVAL = 10; // TODO: calc this based on pool depth https://dev.thorchain.org/thorchain-dev/concepts/streaming-swaps
exports.WETH_TOKEN = new sdk_core_1.Token(Number(caip_1.CHAIN_REFERENCE.EthereumMainnet), '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH', 'Wrapped Ether');
exports.WAVAX_TOKEN = new sdk_core_1.Token(Number(caip_1.CHAIN_REFERENCE.AvalancheCChain), '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', 18, 'WAVAX', 'Wrapped AVAX');
exports.WBNB_TOKEN = new sdk_core_1.Token(Number(caip_1.CHAIN_REFERENCE.BnbSmartChainMainnet), '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', 18, 'WBNB', 'Wrapped BNB');
