"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSwapToJSON = exports.TokenSwapFromJSONTyped = exports.TokenSwapFromJSON = exports.instanceOfTokenSwap = void 0;
const NativeTransfer_1 = require("./NativeTransfer");
const ProgramInfo_1 = require("./ProgramInfo");
const TokenSwapNativeInput_1 = require("./TokenSwapNativeInput");
const TokenTransfer_1 = require("./TokenTransfer");
/**
 * Check if a given object implements the TokenSwap interface.
 */
function instanceOfTokenSwap(value) {
    let isInstance = true;
    isInstance = isInstance && "nativeInput" in value;
    isInstance = isInstance && "nativeOutput" in value;
    isInstance = isInstance && "tokenInputs" in value;
    isInstance = isInstance && "tokenOutputs" in value;
    isInstance = isInstance && "tokenFees" in value;
    isInstance = isInstance && "nativeFees" in value;
    isInstance = isInstance && "programInfo" in value;
    return isInstance;
}
exports.instanceOfTokenSwap = instanceOfTokenSwap;
function TokenSwapFromJSON(json) {
    return TokenSwapFromJSONTyped(json, false);
}
exports.TokenSwapFromJSON = TokenSwapFromJSON;
function TokenSwapFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nativeInput': (0, TokenSwapNativeInput_1.TokenSwapNativeInputFromJSON)(json['nativeInput']),
        'nativeOutput': (0, TokenSwapNativeInput_1.TokenSwapNativeInputFromJSON)(json['nativeOutput']),
        'tokenInputs': (json['tokenInputs'].map(TokenTransfer_1.TokenTransferFromJSON)),
        'tokenOutputs': (json['tokenOutputs'].map(TokenTransfer_1.TokenTransferFromJSON)),
        'tokenFees': (json['tokenFees'].map(TokenTransfer_1.TokenTransferFromJSON)),
        'nativeFees': (json['nativeFees'].map(NativeTransfer_1.NativeTransferFromJSON)),
        'programInfo': (0, ProgramInfo_1.ProgramInfoFromJSON)(json['programInfo']),
    };
}
exports.TokenSwapFromJSONTyped = TokenSwapFromJSONTyped;
function TokenSwapToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nativeInput': (0, TokenSwapNativeInput_1.TokenSwapNativeInputToJSON)(value.nativeInput),
        'nativeOutput': (0, TokenSwapNativeInput_1.TokenSwapNativeInputToJSON)(value.nativeOutput),
        'tokenInputs': (value.tokenInputs.map(TokenTransfer_1.TokenTransferToJSON)),
        'tokenOutputs': (value.tokenOutputs.map(TokenTransfer_1.TokenTransferToJSON)),
        'tokenFees': (value.tokenFees.map(TokenTransfer_1.TokenTransferToJSON)),
        'nativeFees': (value.nativeFees.map(NativeTransfer_1.NativeTransferToJSON)),
        'programInfo': (0, ProgramInfo_1.ProgramInfoToJSON)(value.programInfo),
    };
}
exports.TokenSwapToJSON = TokenSwapToJSON;
