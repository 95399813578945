"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainAdapter = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const hdwallet_core_1 = require("@shapeshiftoss/hdwallet-core");
const types_1 = require("@shapeshiftoss/types");
const unchained = __importStar(require("@shapeshiftoss/unchained-client"));
const ErrorHandler_1 = require("../../error/ErrorHandler");
const types_2 = require("../../types");
const utils_1 = require("../../utils");
const validateAddress_1 = require("../../utils/validateAddress");
const CosmosSdkBaseAdapter_1 = require("../CosmosSdkBaseAdapter");
const types_3 = require("../types");
const SUPPORTED_CHAIN_IDS = [types_1.KnownChainIds.CosmosMainnet];
const DEFAULT_CHAIN_ID = types_1.KnownChainIds.CosmosMainnet;
class ChainAdapter extends CosmosSdkBaseAdapter_1.CosmosSdkBaseAdapter {
    constructor(args) {
        super({
            assetId: caip_1.cosmosAssetId,
            chainId: DEFAULT_CHAIN_ID,
            defaultBIP44Params: ChainAdapter.defaultBIP44Params,
            denom: 'uatom',
            parser: new unchained.cosmos.TransactionParser({
                assetId: caip_1.cosmosAssetId,
                chainId: args.chainId ?? DEFAULT_CHAIN_ID,
                midgardUrl: args.midgardUrl,
            }),
            supportedChainIds: SUPPORTED_CHAIN_IDS,
            ...args,
        });
        this.api = args.providers.http;
    }
    getDisplayName() {
        return types_2.ChainAdapterDisplayName.Cosmos;
    }
    getName() {
        const enumIndex = Object.values(types_2.ChainAdapterDisplayName).indexOf(types_2.ChainAdapterDisplayName.Cosmos);
        return Object.keys(types_2.ChainAdapterDisplayName)[enumIndex];
    }
    getType() {
        return types_1.KnownChainIds.CosmosMainnet;
    }
    getFeeAssetId() {
        return this.assetId;
    }
    async getAddress(input) {
        const { accountNumber, pubKey, wallet, showOnDevice = false } = input;
        if (pubKey)
            return pubKey;
        try {
            if ((0, hdwallet_core_1.supportsCosmos)(wallet)) {
                await (0, utils_1.verifyLedgerAppOpen)(this.chainId, wallet);
                const bip44Params = this.getBIP44Params({ accountNumber });
                const cosmosAddress = await wallet.cosmosGetAddress({
                    addressNList: (0, utils_1.toAddressNList)(bip44Params),
                    showDisplay: showOnDevice,
                });
                if (!cosmosAddress) {
                    throw new Error('Unable to generate Cosmos address.');
                }
                return cosmosAddress;
            }
            else {
                throw new Error('Wallet does not support Cosmos.');
            }
        }
        catch (error) {
            return (0, ErrorHandler_1.ErrorHandler)(error);
        }
    }
    async buildSendApiTransaction(input) {
        try {
            const { sendMax, to, value, from, chainSpecific } = input;
            const { fee } = chainSpecific;
            if (!fee)
                throw new Error('fee is required');
            const account = await this.getAccount(from);
            const amount = this.getAmount({ account, value, fee, sendMax });
            const msg = {
                type: types_3.CosmosSdkMessageType.MsgSend,
                value: {
                    amount: [{ amount, denom: this.denom }],
                    from_address: from,
                    to_address: to,
                },
            };
            return this.buildTransaction({ ...input, account, msg });
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async buildSendTransaction(input) {
        const { accountNumber, wallet } = input;
        const from = await this.getAddress({ accountNumber, wallet });
        return this.buildSendApiTransaction({ ...input, from });
    }
    async buildDelegateTransaction(tx) {
        try {
            const { accountNumber, chainSpecific, sendMax, validator, value, wallet } = tx;
            const { fee } = chainSpecific;
            if (!fee)
                throw new Error('fee is required');
            (0, CosmosSdkBaseAdapter_1.assertIsValidatorAddress)(validator, this.getType());
            const from = await this.getAddress({ accountNumber, wallet });
            const account = await this.getAccount(from);
            const validatorAction = { address: validator, type: 'delegate' };
            const amount = this.getAmount({ account, value, fee, sendMax, validatorAction });
            const msg = {
                type: types_3.CosmosSdkMessageType.MsgDelegate,
                value: {
                    amount: { amount, denom: this.denom },
                    delegator_address: from,
                    validator_address: validator,
                },
            };
            return this.buildTransaction({ ...tx, account, msg });
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async buildUndelegateTransaction(tx) {
        try {
            const { accountNumber, chainSpecific, sendMax, validator, value, wallet } = tx;
            const { fee } = chainSpecific;
            if (!fee)
                throw new Error('fee is required');
            (0, CosmosSdkBaseAdapter_1.assertIsValidatorAddress)(validator, this.getType());
            const from = await this.getAddress({ accountNumber, wallet });
            const account = await this.getAccount(from);
            const validatorAction = { address: validator, type: 'undelegate' };
            const amount = this.getAmount({ account, value, fee, sendMax, validatorAction });
            const msg = {
                type: types_3.CosmosSdkMessageType.MsgUndelegate,
                value: {
                    amount: { amount, denom: this.denom },
                    delegator_address: from,
                    validator_address: validator,
                },
            };
            return this.buildTransaction({ ...tx, account, msg });
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async buildRedelegateTransaction(tx) {
        try {
            const { accountNumber, chainSpecific, fromValidator, sendMax, toValidator, value, wallet } = tx;
            const { fee } = chainSpecific;
            if (!fee)
                throw new Error('fee is required');
            (0, CosmosSdkBaseAdapter_1.assertIsValidatorAddress)(toValidator, this.getType());
            (0, CosmosSdkBaseAdapter_1.assertIsValidatorAddress)(fromValidator, this.getType());
            const from = await this.getAddress({ accountNumber, wallet });
            const account = await this.getAccount(from);
            const validatorAction = { address: fromValidator, type: 'redelegate' };
            const amount = this.getAmount({ account, value, fee, sendMax, validatorAction });
            const msg = {
                type: types_3.CosmosSdkMessageType.MsgBeginRedelegate,
                value: {
                    amount: { amount, denom: this.denom },
                    delegator_address: from,
                    validator_src_address: fromValidator,
                    validator_dst_address: toValidator,
                },
            };
            return this.buildTransaction({ ...tx, account, msg });
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async buildClaimRewardsTransaction(tx) {
        try {
            const { accountNumber, validator, wallet } = tx;
            (0, CosmosSdkBaseAdapter_1.assertIsValidatorAddress)(validator, this.getType());
            const from = await this.getAddress({ accountNumber, wallet });
            const account = await this.getAccount(from);
            const msg = {
                type: types_3.CosmosSdkMessageType.MsgWithdrawDelegationReward,
                value: {
                    delegator_address: from,
                    validator_address: validator,
                },
            };
            return this.buildTransaction({ ...tx, account, msg });
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async signTransaction(signTxInput) {
        try {
            const { txToSign, wallet } = signTxInput;
            if ((0, hdwallet_core_1.supportsCosmos)(wallet)) {
                await (0, utils_1.verifyLedgerAppOpen)(this.chainId, wallet);
                const signedTx = await wallet.cosmosSignTx(txToSign);
                if (!signedTx?.serialized)
                    throw new Error('Error signing tx');
                return signedTx.serialized;
            }
            else {
                throw new Error('Wallet does not support Cosmos.');
            }
        }
        catch (err) {
            return (0, ErrorHandler_1.ErrorHandler)(err);
        }
    }
    async getFeeData(_) {
        const gasLimit = '2000000';
        const fees = await this.api.fees();
        const txFee = (0, utils_1.bnOrZero)(fees[CosmosSdkBaseAdapter_1.Denoms.uatom]).times(gasLimit).toFixed(0);
        return {
            fast: { txFee, chainSpecific: { gasLimit } },
            average: { txFee, chainSpecific: { gasLimit } },
            slow: { txFee, chainSpecific: { gasLimit } },
        };
    }
    async signAndBroadcastTransaction({ senderAddress, receiverAddress, signTxInput, }) {
        await Promise.all([
            (0, validateAddress_1.assertAddressNotSanctioned)(senderAddress),
            receiverAddress !== types_2.CONTRACT_INTERACTION && (0, validateAddress_1.assertAddressNotSanctioned)(receiverAddress),
        ]);
        const { wallet } = signTxInput;
        try {
            if ((0, hdwallet_core_1.supportsCosmos)(wallet)) {
                const signedTx = await this.signTransaction(signTxInput);
                return this.providers.http.sendTx({ body: { rawTx: signedTx } });
            }
            else {
                throw new Error('Wallet does not support Cosmos.');
            }
        }
        catch (error) {
            return (0, ErrorHandler_1.ErrorHandler)(error);
        }
    }
}
exports.ChainAdapter = ChainAdapter;
ChainAdapter.defaultBIP44Params = {
    purpose: 44,
    coinType: Number(caip_1.ASSET_REFERENCE.Cosmos),
    accountNumber: 0,
};
