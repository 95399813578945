"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BRIDGE_TYPE = void 0;
var BRIDGE_TYPE;
(function (BRIDGE_TYPE) {
    BRIDGE_TYPE["ETH_DEPOSIT"] = "ETH Deposit";
    BRIDGE_TYPE["ERC20_DEPOSIT"] = "ERC20 Deposit";
    BRIDGE_TYPE["ETH_WITHDRAWAL"] = "ETH Withdrawal";
    BRIDGE_TYPE["ERC20_WITHDRAWAL"] = "ERC20 Withdrawal";
})(BRIDGE_TYPE || (exports.BRIDGE_TYPE = BRIDGE_TYPE = {}));
