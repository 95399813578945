"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configureLiFi = void 0;
const sdk_1 = require("@lifi/sdk");
const constants_1 = require("./constants");
// Mandatory to pass the configuration object
const lifiConfig = {
    disableVersionCheck: true,
    integrator: constants_1.LIFI_INTEGRATOR_ID,
};
// Configure LiFi SDK as a singleton, i.e in case it hasn't been already
const configureLiFi = () => {
    sdk_1.config.set(lifiConfig);
};
exports.configureLiFi = configureLiFi;
