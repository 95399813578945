"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenStandardToJSON = exports.TokenStandardFromJSONTyped = exports.TokenStandardFromJSON = exports.TokenStandard = void 0;
/**
 *
 * @export
 */
exports.TokenStandard = {
    ProgrammableNonFungible: 'ProgrammableNonFungible',
    NonFungible: 'NonFungible',
    Fungible: 'Fungible',
    FungibleAsset: 'FungibleAsset',
    NonFungibleEdition: 'NonFungibleEdition',
    UnknownStandard: 'UnknownStandard'
};
function TokenStandardFromJSON(json) {
    return TokenStandardFromJSONTyped(json, false);
}
exports.TokenStandardFromJSON = TokenStandardFromJSON;
function TokenStandardFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TokenStandardFromJSONTyped = TokenStandardFromJSONTyped;
function TokenStandardToJSON(value) {
    return value;
}
exports.TokenStandardToJSON = TokenStandardToJSON;
