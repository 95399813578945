"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTokenAmountToJSON = exports.RawTokenAmountFromJSONTyped = exports.RawTokenAmountFromJSON = exports.instanceOfRawTokenAmount = void 0;
/**
 * Check if a given object implements the RawTokenAmount interface.
 */
function instanceOfRawTokenAmount(value) {
    let isInstance = true;
    isInstance = isInstance && "tokenAmount" in value;
    isInstance = isInstance && "decimals" in value;
    return isInstance;
}
exports.instanceOfRawTokenAmount = instanceOfRawTokenAmount;
function RawTokenAmountFromJSON(json) {
    return RawTokenAmountFromJSONTyped(json, false);
}
exports.RawTokenAmountFromJSON = RawTokenAmountFromJSON;
function RawTokenAmountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tokenAmount': json['tokenAmount'],
        'decimals': json['decimals'],
    };
}
exports.RawTokenAmountFromJSONTyped = RawTokenAmountFromJSONTyped;
function RawTokenAmountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tokenAmount': value.tokenAmount,
        'decimals': value.decimals,
    };
}
exports.RawTokenAmountToJSON = RawTokenAmountToJSON;
