"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bnbsmartchain-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTxHistoryTxToJSON = exports.BaseTxHistoryTxFromJSONTyped = exports.BaseTxHistoryTxFromJSON = exports.instanceOfBaseTxHistoryTx = void 0;
const runtime_1 = require("../runtime");
const Tx_1 = require("./Tx");
/**
 * Check if a given object implements the BaseTxHistoryTx interface.
 */
function instanceOfBaseTxHistoryTx(value) {
    let isInstance = true;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "txs" in value;
    return isInstance;
}
exports.instanceOfBaseTxHistoryTx = instanceOfBaseTxHistoryTx;
function BaseTxHistoryTxFromJSON(json) {
    return BaseTxHistoryTxFromJSONTyped(json, false);
}
exports.BaseTxHistoryTxFromJSON = BaseTxHistoryTxFromJSON;
function BaseTxHistoryTxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cursor': !(0, runtime_1.exists)(json, 'cursor') ? undefined : json['cursor'],
        'pubkey': json['pubkey'],
        'txs': (json['txs'].map(Tx_1.TxFromJSON)),
    };
}
exports.BaseTxHistoryTxFromJSONTyped = BaseTxHistoryTxFromJSONTyped;
function BaseTxHistoryTxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cursor': value.cursor,
        'pubkey': value.pubkey,
        'txs': (value.txs.map(Tx_1.TxToJSON)),
    };
}
exports.BaseTxHistoryTxToJSON = BaseTxHistoryTxToJSON;
