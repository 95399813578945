"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalsSupportedChainIds = void 0;
const types_1 = require("@shapeshiftoss/types");
// https://api.portals.fi/v1/networks
exports.PortalsSupportedChainIds = [
    types_1.KnownChainIds.EthereumMainnet,
    types_1.KnownChainIds.ArbitrumMainnet,
    types_1.KnownChainIds.AvalancheMainnet,
    types_1.KnownChainIds.PolygonMainnet,
    types_1.KnownChainIds.BnbSmartChainMainnet,
    types_1.KnownChainIds.OptimismMainnet,
    types_1.KnownChainIds.GnosisMainnet,
    types_1.KnownChainIds.BaseMainnet,
];
