"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimateGasRequestToJSON = exports.EstimateGasRequestFromJSONTyped = exports.EstimateGasRequestFromJSON = exports.instanceOfEstimateGasRequest = void 0;
/**
 * Check if a given object implements the EstimateGasRequest interface.
 */
function instanceOfEstimateGasRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "rawTx" in value;
    return isInstance;
}
exports.instanceOfEstimateGasRequest = instanceOfEstimateGasRequest;
function EstimateGasRequestFromJSON(json) {
    return EstimateGasRequestFromJSONTyped(json, false);
}
exports.EstimateGasRequestFromJSON = EstimateGasRequestFromJSON;
function EstimateGasRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rawTx': json['rawTx'],
    };
}
exports.EstimateGasRequestFromJSONTyped = EstimateGasRequestFromJSONTyped;
function EstimateGasRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rawTx': value.rawTx,
    };
}
exports.EstimateGasRequestToJSON = EstimateGasRequestToJSON;
