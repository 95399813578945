"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.thorchainSwapper = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const utils_2 = require("../../utils");
const constants_1 = require("./constants");
const poolAssetHelpers_1 = require("./utils/poolAssetHelpers/poolAssetHelpers");
const thorService_1 = require("./utils/thorService");
const getSupportedAssets = async (config) => {
    const daemonUrl = config.REACT_APP_THORCHAIN_NODE_URL;
    const thorchainSwapLongtailEnabled = config.REACT_APP_FEATURE_THORCHAINSWAP_LONGTAIL;
    let supportedSellAssetIds = [caip_1.thorchainAssetId];
    let supportedBuyAssetIds = [caip_1.thorchainAssetId];
    const poolResponse = await thorService_1.thorService.get(`${daemonUrl}/lcd/thorchain/pools`);
    const longtailTokensJson = await Promise.resolve().then(() => __importStar(require('./generated/generatedThorLongtailTokens.json')));
    const longtailTokens = longtailTokensJson.default;
    const l1Tokens = poolResponse.isOk()
        ? poolResponse
            .unwrap()
            .data.filter(pool => pool.status === 'Available')
            .map(pool => (0, poolAssetHelpers_1.poolAssetIdToAssetId)(pool.asset))
            .filter(utils_1.isSome)
        : [];
    const allTokens = thorchainSwapLongtailEnabled ? [...longtailTokens, ...l1Tokens] : l1Tokens;
    allTokens.forEach(assetId => {
        const chainId = (0, caip_1.fromAssetId)(assetId).chainId;
        constants_1.sellSupportedChainIds[chainId] && supportedSellAssetIds.push(assetId);
        constants_1.buySupportedChainIds[chainId] && supportedBuyAssetIds.push(assetId);
    });
    return { supportedSellAssetIds, supportedBuyAssetIds };
};
exports.thorchainSwapper = {
    executeEvmTransaction: utils_2.executeEvmTransaction,
    executeCosmosSdkTransaction: async (txToSign, { signAndBroadcastTransaction }) => {
        return await signAndBroadcastTransaction(txToSign);
    },
    executeUtxoTransaction: async (txToSign, { signAndBroadcastTransaction }) => {
        return await signAndBroadcastTransaction(txToSign);
    },
    filterAssetIdsBySellable: async (_, config) => await getSupportedAssets(config).then(({ supportedSellAssetIds }) => supportedSellAssetIds),
    filterBuyAssetsBySellAssetId: async ({ assets, sellAsset, config, }) => {
        const { supportedSellAssetIds, supportedBuyAssetIds } = await getSupportedAssets(config);
        if (!supportedSellAssetIds.includes(sellAsset.assetId))
            return [];
        return assets
            .filter(asset => supportedBuyAssetIds.includes(asset.assetId) && asset.assetId !== sellAsset.assetId)
            .map(asset => asset.assetId);
    },
};
