"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zerionChainIdToChainId = exports.ZERION_CHAINS_MAP = exports.ZERION_CHAINS = void 0;
const caip_1 = require("@shapeshiftoss/caip");
exports.ZERION_CHAINS = [
    // shapeshift supported
    'avalanche',
    'binance-smart-chain',
    'ethereum',
    'optimism',
    'polygon',
    'arbitrum',
    'xdai',
    'base',
    // not yet
    // 'aurora',
    // 'fantom',
    // 'solana',
];
exports.ZERION_CHAINS_MAP = {
    avalanche: caip_1.avalancheChainId,
    'binance-smart-chain': caip_1.bscChainId,
    ethereum: caip_1.ethChainId,
    optimism: caip_1.optimismChainId,
    polygon: caip_1.polygonChainId,
    arbitrum: caip_1.arbitrumChainId,
    xdai: caip_1.gnosisChainId,
    base: caip_1.baseChainId,
};
const zerionChainIdToChainId = (chainId) => exports.ZERION_CHAINS_MAP[chainId];
exports.zerionChainIdToChainId = zerionChainIdToChainId;
