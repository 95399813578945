"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseAccountToJSON = exports.BaseAccountFromJSONTyped = exports.BaseAccountFromJSON = exports.instanceOfBaseAccount = void 0;
/**
 * Check if a given object implements the BaseAccount interface.
 */
function instanceOfBaseAccount(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "unconfirmedBalance" in value;
    return isInstance;
}
exports.instanceOfBaseAccount = instanceOfBaseAccount;
function BaseAccountFromJSON(json) {
    return BaseAccountFromJSONTyped(json, false);
}
exports.BaseAccountFromJSON = BaseAccountFromJSON;
function BaseAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'pubkey': json['pubkey'],
        'unconfirmedBalance': json['unconfirmedBalance'],
    };
}
exports.BaseAccountFromJSONTyped = BaseAccountFromJSONTyped;
function BaseAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'pubkey': value.pubkey,
        'unconfirmedBalance': value.unconfirmedBalance,
    };
}
exports.BaseAccountToJSON = BaseAccountToJSON;
