"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUniqueAddressSubstring = void 0;
const getUniqueAddressSubstring = (destinationAssetId, longTailAssetIds) => {
    const MINIMUM_UNIQUE_SUBSTRING = 2;
    let maybeShortenedDestinationAddress = destinationAssetId;
    const substringsCount = {};
    for (let length = MINIMUM_UNIQUE_SUBSTRING; length <= destinationAssetId.length - 2; length++) {
        const currentSubstring = destinationAssetId.slice(-length);
        substringsCount[currentSubstring] = 0;
    }
    longTailAssetIds.forEach(assetId => {
        Object.keys(substringsCount).forEach(substring => {
            if (assetId.includes(substring)) {
                substringsCount[substring] += 1;
            }
        });
    });
    for (const [substring, count] of Object.entries(substringsCount)) {
        if (count === 1) {
            maybeShortenedDestinationAddress = substring;
            break;
        }
    }
    return maybeShortenedDestinationAddress;
};
exports.getUniqueAddressSubstring = getUniqueAddressSubstring;
