"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain Unchained API
 * Provides access to thorchain chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTxHistoryAllOfToJSON = exports.BaseTxHistoryAllOfFromJSONTyped = exports.BaseTxHistoryAllOfFromJSON = exports.instanceOfBaseTxHistoryAllOf = void 0;
const Tx_1 = require("./Tx");
/**
 * Check if a given object implements the BaseTxHistoryAllOf interface.
 */
function instanceOfBaseTxHistoryAllOf(value) {
    let isInstance = true;
    isInstance = isInstance && "pubkey" in value;
    isInstance = isInstance && "txs" in value;
    return isInstance;
}
exports.instanceOfBaseTxHistoryAllOf = instanceOfBaseTxHistoryAllOf;
function BaseTxHistoryAllOfFromJSON(json) {
    return BaseTxHistoryAllOfFromJSONTyped(json, false);
}
exports.BaseTxHistoryAllOfFromJSON = BaseTxHistoryAllOfFromJSON;
function BaseTxHistoryAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pubkey': json['pubkey'],
        'txs': (json['txs'].map(Tx_1.TxFromJSON)),
    };
}
exports.BaseTxHistoryAllOfFromJSONTyped = BaseTxHistoryAllOfFromJSONTyped;
function BaseTxHistoryAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pubkey': value.pubkey,
        'txs': (value.txs.map(Tx_1.TxToJSON)),
    };
}
exports.BaseTxHistoryAllOfToJSON = BaseTxHistoryAllOfToJSON;
