"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrichedTransactionToJSON = exports.EnrichedTransactionFromJSONTyped = exports.EnrichedTransactionFromJSON = exports.instanceOfEnrichedTransaction = void 0;
const AccountData_1 = require("./AccountData");
const EnrichedTransactionTransactionError_1 = require("./EnrichedTransactionTransactionError");
const Instruction_1 = require("./Instruction");
const NativeTransfer_1 = require("./NativeTransfer");
const Source_1 = require("./Source");
const TokenTransfer_1 = require("./TokenTransfer");
const TransactionEvent_1 = require("./TransactionEvent");
const TransactionType_1 = require("./TransactionType");
/**
 * Check if a given object implements the EnrichedTransaction interface.
 */
function instanceOfEnrichedTransaction(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "feePayer" in value;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "slot" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "nativeTransfers" in value;
    isInstance = isInstance && "tokenTransfers" in value;
    isInstance = isInstance && "accountData" in value;
    isInstance = isInstance && "transactionError" in value;
    isInstance = isInstance && "instructions" in value;
    isInstance = isInstance && "events" in value;
    return isInstance;
}
exports.instanceOfEnrichedTransaction = instanceOfEnrichedTransaction;
function EnrichedTransactionFromJSON(json) {
    return EnrichedTransactionFromJSONTyped(json, false);
}
exports.EnrichedTransactionFromJSON = EnrichedTransactionFromJSON;
function EnrichedTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'type': (0, TransactionType_1.TransactionTypeFromJSON)(json['type']),
        'source': (0, Source_1.SourceFromJSON)(json['source']),
        'fee': json['fee'],
        'feePayer': json['feePayer'],
        'signature': json['signature'],
        'slot': json['slot'],
        'timestamp': json['timestamp'],
        'nativeTransfers': (json['nativeTransfers'] === null ? null : json['nativeTransfers'].map(NativeTransfer_1.NativeTransferFromJSON)),
        'tokenTransfers': (json['tokenTransfers'] === null ? null : json['tokenTransfers'].map(TokenTransfer_1.TokenTransferFromJSON)),
        'accountData': (json['accountData'].map(AccountData_1.AccountDataFromJSON)),
        'transactionError': (0, EnrichedTransactionTransactionError_1.EnrichedTransactionTransactionErrorFromJSON)(json['transactionError']),
        'instructions': (json['instructions'].map(Instruction_1.InstructionFromJSON)),
        'events': (0, TransactionEvent_1.TransactionEventFromJSON)(json['events']),
    };
}
exports.EnrichedTransactionFromJSONTyped = EnrichedTransactionFromJSONTyped;
function EnrichedTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'type': (0, TransactionType_1.TransactionTypeToJSON)(value.type),
        'source': (0, Source_1.SourceToJSON)(value.source),
        'fee': value.fee,
        'feePayer': value.feePayer,
        'signature': value.signature,
        'slot': value.slot,
        'timestamp': value.timestamp,
        'nativeTransfers': (value.nativeTransfers === null ? null : value.nativeTransfers.map(NativeTransfer_1.NativeTransferToJSON)),
        'tokenTransfers': (value.tokenTransfers === null ? null : value.tokenTransfers.map(TokenTransfer_1.TokenTransferToJSON)),
        'accountData': (value.accountData.map(AccountData_1.AccountDataToJSON)),
        'transactionError': (0, EnrichedTransactionTransactionError_1.EnrichedTransactionTransactionErrorToJSON)(value.transactionError),
        'instructions': (value.instructions.map(Instruction_1.InstructionToJSON)),
        'events': (0, TransactionEvent_1.TransactionEventToJSON)(value.events),
    };
}
exports.EnrichedTransactionToJSON = EnrichedTransactionToJSON;
