"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zrxSwapper = void 0;
const utils_1 = require("../../utils");
const filterAssetIdsBySellable_1 = require("../utils/filterAssetIdsBySellable/filterAssetIdsBySellable");
const filterBuyAssetsBySellAssetId_1 = require("../utils/filterBuyAssetsBySellAssetId/filterBuyAssetsBySellAssetId");
const constants_1 = require("./utils/constants");
exports.zrxSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve((0, filterAssetIdsBySellable_1.filterEvmAssetIdsBySellable)(assets)
            .filter(asset => {
            const { assetId, chainId } = asset;
            return (!constants_1.ZRX_UNSUPPORTED_ASSETS.includes(assetId) &&
                constants_1.ZRX_SUPPORTED_CHAINIDS.includes(chainId));
        })
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve((0, filterBuyAssetsBySellAssetId_1.filterSameChainEvmBuyAssetsBySellAssetId)(input)
            .filter(asset => {
            const { assetId, chainId } = asset;
            return (!constants_1.ZRX_UNSUPPORTED_ASSETS.includes(assetId) &&
                constants_1.ZRX_SUPPORTED_CHAINIDS.includes(chainId));
        })
            .map(asset => asset.assetId));
    },
};
