"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usdcAsset = exports.CHAINFLIP_DCA_BOOST_SWAP_SOURCE = exports.CHAINFLIP_DCA_SWAP_SOURCE = exports.CHAINFLIP_BOOST_SWAP_SOURCE = exports.CHAINFLIP_SWAP_SOURCE = exports.CHAINFLIP_SUPPORTED_CHAIN_IDS = exports.chainIdToChainflipNetwork = exports.ChainflipSupportedAssetIdsByChainId = exports.ChainflipSupportedChainIds = exports.CHAINFLIP_BAAS_COMMISSION = exports.CHAINFLIP_DCA_QUOTE = exports.CHAINFLIP_REGULAR_QUOTE = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const types_2 = require("../../types");
const types_3 = require("./types");
exports.CHAINFLIP_REGULAR_QUOTE = 'regular';
exports.CHAINFLIP_DCA_QUOTE = 'dca';
exports.CHAINFLIP_BAAS_COMMISSION = 5;
exports.ChainflipSupportedChainIds = [
    types_1.KnownChainIds.EthereumMainnet,
    types_1.KnownChainIds.ArbitrumMainnet,
    types_1.KnownChainIds.BitcoinMainnet,
    types_1.KnownChainIds.SolanaMainnet,
];
exports.ChainflipSupportedAssetIdsByChainId = {
    [types_1.KnownChainIds.EthereumMainnet]: [caip_1.ethAssetId, caip_1.flipAssetId, caip_1.usdcAssetId, caip_1.usdtAssetId],
    [types_1.KnownChainIds.ArbitrumMainnet]: [caip_1.arbitrumAssetId, caip_1.usdcOnArbitrumOneAssetId],
    [types_1.KnownChainIds.BitcoinMainnet]: [caip_1.btcAssetId],
    [types_1.KnownChainIds.SolanaMainnet]: [caip_1.solAssetId, caip_1.usdcOnSolanaAssetId],
};
exports.chainIdToChainflipNetwork = {
    [types_1.KnownChainIds.EthereumMainnet]: types_3.ChainflipNetwork.Ethereum,
    [types_1.KnownChainIds.ArbitrumMainnet]: types_3.ChainflipNetwork.Arbitrum,
    [types_1.KnownChainIds.BitcoinMainnet]: types_3.ChainflipNetwork.Bitcoin,
    [types_1.KnownChainIds.SolanaMainnet]: types_3.ChainflipNetwork.Solana,
};
exports.CHAINFLIP_SUPPORTED_CHAIN_IDS = {
    sell: exports.ChainflipSupportedChainIds,
    buy: exports.ChainflipSupportedChainIds,
};
exports.CHAINFLIP_SWAP_SOURCE = types_2.SwapperName.Chainflip;
exports.CHAINFLIP_BOOST_SWAP_SOURCE = `${types_2.SwapperName.Chainflip} • Boost`;
exports.CHAINFLIP_DCA_SWAP_SOURCE = `${types_2.SwapperName.Chainflip} • DCA`;
exports.CHAINFLIP_DCA_BOOST_SWAP_SOURCE = `${types_2.SwapperName.Chainflip} • DCA • Boost`;
exports.usdcAsset = {
    assetId: 'eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    chainId: types_1.KnownChainIds.EthereumMainnet,
    color: '#2373CB',
    explorer: 'https://etherscan.io',
    explorerAddressLink: 'https://etherscan.io/address/',
    explorerTxLink: 'https://etherscan.io/tx/',
    icon: 'https://rawcdn.githack.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USDC on Ethereum',
    precision: 6,
    relatedAssetKey: 'eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    symbol: 'USDC',
};
