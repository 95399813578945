"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTransactionParser = void 0;
const bignumber_js_1 = require("bignumber.js");
const types_1 = require("../../types");
const utils_1 = require("../../utils");
__exportStar(require("./types"), exports);
class BaseTransactionParser {
    constructor(args) {
        this.parsers = [];
        this.chainId = args.chainId;
        this.assetId = args.assetId;
    }
    /**
     * Register custom transaction sub parser to parse custom op return data
     *
     * _parsers should be registered from most generic first to most specific last_
     */
    registerParser(parser) {
        this.parsers.unshift(parser);
    }
    registerParsers(parsers) {
        parsers.forEach(parser => this.registerParser(parser));
    }
    async parse(tx, address) {
        const parserResults = await (async () => {
            for (const parser of this.parsers) {
                const result = await parser.parse(tx, address);
                if (result)
                    return result;
            }
        })();
        const parsedTx = {
            address,
            blockHash: tx.blockHash,
            blockHeight: tx.blockHeight,
            blockTime: tx.timestamp,
            chainId: this.chainId,
            confirmations: tx.confirmations,
            status: tx.confirmations > 0 ? types_1.TxStatus.Confirmed : types_1.TxStatus.Pending,
            transfers: parserResults?.transfers ?? [],
            txid: tx.txid,
            trade: parserResults?.trade,
            data: parserResults?.data,
        };
        tx.vin.forEach(vin => {
            if (vin.addresses?.includes(address)) {
                // send amount
                const sendValue = new bignumber_js_1.BigNumber(vin.value ?? 0);
                if (sendValue.gt(0)) {
                    parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                        assetId: this.assetId,
                        from: vin.addresses?.[0] ?? '',
                        to: tx.vout[0].addresses?.[0] ?? '',
                        transfers: parsedTx.transfers,
                        type: types_1.TransferType.Send,
                        value: sendValue.toString(10),
                    });
                }
                // network fee
                const fees = new bignumber_js_1.BigNumber(tx.fee ?? 0);
                if (fees.gt(0)) {
                    parsedTx.fee = { assetId: this.assetId, value: fees.toString(10) };
                }
            }
        });
        tx.vout.forEach(vout => {
            if (vout.addresses?.includes(address)) {
                // receive amount
                const receiveValue = new bignumber_js_1.BigNumber(vout.value ?? 0);
                if (receiveValue.gt(0)) {
                    parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                        assetId: this.assetId,
                        from: tx.vin[0].addresses?.[0] ?? '',
                        to: vout.addresses?.[0] ?? '',
                        transfers: parsedTx.transfers,
                        type: types_1.TransferType.Receive,
                        value: receiveValue.toString(10),
                    });
                }
            }
        });
        return Promise.resolve(parsedTx);
    }
}
exports.BaseTransactionParser = BaseTransactionParser;
