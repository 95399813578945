"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain-V1 Unchained API
 * Provides access to thorchain-v1 chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorsToJSON = exports.ValidatorsFromJSONTyped = exports.ValidatorsFromJSON = exports.instanceOfValidators = void 0;
const Validator_1 = require("./Validator");
/**
 * Check if a given object implements the Validators interface.
 */
function instanceOfValidators(value) {
    let isInstance = true;
    isInstance = isInstance && "cursor" in value;
    isInstance = isInstance && "validators" in value;
    return isInstance;
}
exports.instanceOfValidators = instanceOfValidators;
function ValidatorsFromJSON(json) {
    return ValidatorsFromJSONTyped(json, false);
}
exports.ValidatorsFromJSON = ValidatorsFromJSON;
function ValidatorsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cursor': json['cursor'],
        'validators': (json['validators'].map(Validator_1.ValidatorFromJSON)),
    };
}
exports.ValidatorsFromJSONTyped = ValidatorsFromJSONTyped;
function ValidatorsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cursor': value.cursor,
        'validators': (value.validators.map(Validator_1.ValidatorToJSON)),
    };
}
exports.ValidatorsToJSON = ValidatorsToJSON;
