"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain-V1 Unchained API
 * Provides access to thorchain-v1 chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorUnbondingToJSON = exports.ValidatorUnbondingFromJSONTyped = exports.ValidatorUnbondingFromJSON = exports.instanceOfValidatorUnbonding = void 0;
/**
 * Check if a given object implements the ValidatorUnbonding interface.
 */
function instanceOfValidatorUnbonding(value) {
    let isInstance = true;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "timestamp" in value;
    return isInstance;
}
exports.instanceOfValidatorUnbonding = instanceOfValidatorUnbonding;
function ValidatorUnbondingFromJSON(json) {
    return ValidatorUnbondingFromJSONTyped(json, false);
}
exports.ValidatorUnbondingFromJSON = ValidatorUnbondingFromJSON;
function ValidatorUnbondingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'height': json['height'],
        'timestamp': json['timestamp'],
    };
}
exports.ValidatorUnbondingFromJSONTyped = ValidatorUnbondingFromJSONTyped;
function ValidatorUnbondingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'height': value.height,
        'timestamp': value.timestamp,
    };
}
exports.ValidatorUnbondingToJSON = ValidatorUnbondingToJSON;
