"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeAsset = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chainIdToFeeAsset_1 = require("../chainIdToFeeAsset");
const sha256_1 = require("../sha256");
/**
 * utility to create an asset from minimal asset data from external sources at runtime
 * e.g. zapper/zerion/etherscan
 * required fields are assetId, symbol, name, precision
 * the rest can be inferred from existing data
 */
const makeAsset = (assetsById, minimalAsset) => {
    const { assetId } = minimalAsset;
    const color = (() => {
        if (minimalAsset.color)
            return minimalAsset.color;
        const shaAssetId = (0, sha256_1.sha256)(assetId);
        return `#${shaAssetId.slice(0, 6)}`;
    })();
    const chainId = (() => {
        if (minimalAsset.chainId)
            return minimalAsset.chainId;
        return (0, caip_1.fromAssetId)(assetId).chainId;
    })();
    // currently, dynamic assets are LP pairs, and they have two icon urls and are rendered differently
    const icon = minimalAsset?.icon ?? '';
    const explorerLinks = (() => {
        const feeAsset = (0, chainIdToFeeAsset_1.chainIdToFeeAsset)(assetsById, chainId);
        return {
            explorer: feeAsset?.explorer ?? '',
            explorerTxLink: feeAsset?.explorerTxLink ?? '',
            explorerAddressLink: feeAsset?.explorerAddressLink ?? '',
        };
    })();
    return Object.assign({}, minimalAsset, explorerLinks, {
        chainId,
        color,
        icon,
        relatedAssetKey: null,
    });
};
exports.makeAsset = makeAsset;
