"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimateFeesBodyAnyOf1ToJSON = exports.EstimateFeesBodyAnyOf1FromJSONTyped = exports.EstimateFeesBodyAnyOf1FromJSON = exports.instanceOfEstimateFeesBodyAnyOf1 = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the EstimateFeesBodyAnyOf1 interface.
 */
function instanceOfEstimateFeesBodyAnyOf1(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfEstimateFeesBodyAnyOf1 = instanceOfEstimateFeesBodyAnyOf1;
function EstimateFeesBodyAnyOf1FromJSON(json) {
    return EstimateFeesBodyAnyOf1FromJSONTyped(json, false);
}
exports.EstimateFeesBodyAnyOf1FromJSON = EstimateFeesBodyAnyOf1FromJSON;
function EstimateFeesBodyAnyOf1FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.EstimateFeesBodyAnyOf1FromJSONTyped = EstimateFeesBodyAnyOf1FromJSONTyped;
function EstimateFeesBodyAnyOf1ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
    };
}
exports.EstimateFeesBodyAnyOf1ToJSON = EstimateFeesBodyAnyOf1ToJSON;
