"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSwapNativeInputToJSON = exports.TokenSwapNativeInputFromJSONTyped = exports.TokenSwapNativeInputFromJSON = exports.instanceOfTokenSwapNativeInput = void 0;
/**
 * Check if a given object implements the TokenSwapNativeInput interface.
 */
function instanceOfTokenSwapNativeInput(value) {
    let isInstance = true;
    isInstance = isInstance && "fromUserAccount" in value;
    isInstance = isInstance && "toUserAccount" in value;
    isInstance = isInstance && "amount" in value;
    return isInstance;
}
exports.instanceOfTokenSwapNativeInput = instanceOfTokenSwapNativeInput;
function TokenSwapNativeInputFromJSON(json) {
    return TokenSwapNativeInputFromJSONTyped(json, false);
}
exports.TokenSwapNativeInputFromJSON = TokenSwapNativeInputFromJSON;
function TokenSwapNativeInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fromUserAccount': json['fromUserAccount'],
        'toUserAccount': json['toUserAccount'],
        'amount': json['amount'],
    };
}
exports.TokenSwapNativeInputFromJSONTyped = TokenSwapNativeInputFromJSONTyped;
function TokenSwapNativeInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fromUserAccount': value.fromUserAccount,
        'toUserAccount': value.toUserAccount,
        'amount': value.amount,
    };
}
exports.TokenSwapNativeInputToJSON = TokenSwapNativeInputToJSON;
