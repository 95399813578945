"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainflipService = void 0;
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const maxAge = 15 * 1000; // 15 seconds ttl for all except cached to get fresh status updates
const cachedUrls = ['/assets/'];
const axiosConfig = {
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const chainflipServiceBase = (0, utils_1.createCache)(maxAge, cachedUrls, axiosConfig);
exports.chainflipService = (0, utils_1.makeSwapperAxiosServiceMonadic)(chainflipServiceBase, types_1.SwapperName.Chainflip);
