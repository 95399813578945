"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/gnosis-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RPCRequestToJSON = exports.RPCRequestFromJSONTyped = exports.RPCRequestFromJSON = exports.instanceOfRPCRequest = exports.RPCRequestJsonrpcEnum = void 0;
const runtime_1 = require("../runtime");
const RPCResponseId_1 = require("./RPCResponseId");
/**
 * @export
 */
exports.RPCRequestJsonrpcEnum = {
    _20: '2.0'
};
/**
 * Check if a given object implements the RPCRequest interface.
 */
function instanceOfRPCRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "jsonrpc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "method" in value;
    return isInstance;
}
exports.instanceOfRPCRequest = instanceOfRPCRequest;
function RPCRequestFromJSON(json) {
    return RPCRequestFromJSONTyped(json, false);
}
exports.RPCRequestFromJSON = RPCRequestFromJSON;
function RPCRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'jsonrpc': json['jsonrpc'],
        'id': (0, RPCResponseId_1.RPCResponseIdFromJSON)(json['id']),
        'method': json['method'],
        'params': !(0, runtime_1.exists)(json, 'params') ? undefined : json['params'],
    };
}
exports.RPCRequestFromJSONTyped = RPCRequestFromJSONTyped;
function RPCRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'jsonrpc': value.jsonrpc,
        'id': (0, RPCResponseId_1.RPCResponseIdToJSON)(value.id),
        'method': value.method,
        'params': value.params,
    };
}
exports.RPCRequestToJSON = RPCRequestToJSON;
