"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTreasuryAddressFromChainId = exports.isNativeEvmAsset = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const isNativeEvmAsset = (assetId) => {
    const { chainId } = (0, caip_1.fromAssetId)(assetId);
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return assetId === caip_1.ethAssetId;
        case types_1.KnownChainIds.AvalancheMainnet:
            return assetId === caip_1.avalancheAssetId;
        case types_1.KnownChainIds.OptimismMainnet:
            return assetId === caip_1.optimismAssetId;
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return assetId === caip_1.bscAssetId;
        case types_1.KnownChainIds.PolygonMainnet:
            return assetId === caip_1.polygonAssetId;
        case types_1.KnownChainIds.GnosisMainnet:
            return assetId === caip_1.gnosisAssetId;
        case types_1.KnownChainIds.ArbitrumMainnet:
            return assetId === caip_1.arbitrumAssetId;
        case types_1.KnownChainIds.BaseMainnet:
            return assetId === caip_1.baseAssetId;
        default:
            return false;
    }
};
exports.isNativeEvmAsset = isNativeEvmAsset;
const DAO_TREASURY_BY_CHAIN_ID = {
    [types_1.KnownChainIds.EthereumMainnet]: utils_1.DAO_TREASURY_ETHEREUM_MAINNET,
    [types_1.KnownChainIds.OptimismMainnet]: utils_1.DAO_TREASURY_OPTIMISM,
    [types_1.KnownChainIds.AvalancheMainnet]: utils_1.DAO_TREASURY_AVALANCHE,
    [types_1.KnownChainIds.PolygonMainnet]: utils_1.DAO_TREASURY_POLYGON,
    [types_1.KnownChainIds.GnosisMainnet]: utils_1.DAO_TREASURY_GNOSIS,
    [types_1.KnownChainIds.BnbSmartChainMainnet]: utils_1.DAO_TREASURY_BSC,
    [types_1.KnownChainIds.ArbitrumMainnet]: utils_1.DAO_TREASURY_ARBITRUM,
    [types_1.KnownChainIds.BaseMainnet]: utils_1.DAO_TREASURY_BASE,
};
const getTreasuryAddressFromChainId = (chainId) => {
    const maybeEvmChainId = (0, utils_1.isEvmTreasuryChainId)(chainId) ? chainId : undefined;
    const treasuryAddress = maybeEvmChainId ? DAO_TREASURY_BY_CHAIN_ID[maybeEvmChainId] : undefined;
    if (!treasuryAddress)
        throw new Error(`[getTreasuryAddressFromChainId] - Unsupported chainId: ${chainId}`);
    return treasuryAddress;
};
exports.getTreasuryAddressFromChainId = getTreasuryAddressFromChainId;
