"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainIdToSafeBaseUrl = void 0;
const caip_1 = require("@shapeshiftoss/caip");
exports.ChainIdToSafeBaseUrl = {
    [caip_1.ethChainId]: 'https://safe-transaction-mainnet.safe.global',
    [caip_1.avalancheChainId]: 'https://safe-transaction-avalanche.safe.global',
    [caip_1.optimismChainId]: 'https://safe-transaction-optimism.safe.global',
    [caip_1.bscChainId]: 'https://safe-transaction-bsc.safe.global',
    [caip_1.polygonChainId]: 'https://safe-transaction-polygon.safe.global',
    [caip_1.gnosisChainId]: 'https://safe-transaction-gnosis-chain.safe.global',
    [caip_1.arbitrumChainId]: 'https://safe-transaction-arbitrum.safe.global',
    [caip_1.baseChainId]: 'https://safe-transaction-base.safe.global',
};
