"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const thorchain_1 = require("../../parser/thorchain");
const opReturnRegex = /OP_RETURN \((?<memo>[^)]+)\)/;
class Parser {
    constructor(args) {
        this.thorchainParser = new thorchain_1.Parser({ midgardUrl: args.midgardUrl });
    }
    async parse(tx) {
        const opReturn = tx.vout.find(vout => vout.opReturn)?.opReturn;
        if (!opReturn)
            return;
        const memo = opReturn.match(opReturnRegex)?.groups?.memo;
        if (!memo)
            return;
        return await this.thorchainParser.parse(memo);
    }
}
exports.Parser = Parser;
