"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainIdToFeeAssetId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const assertUnreachable_1 = require("./assertUnreachable");
const chainIdToFeeAssetId = (_chainId) => {
    const chainId = _chainId;
    switch (chainId) {
        case types_1.KnownChainIds.ArbitrumMainnet:
            return caip_1.arbitrumAssetId;
        case types_1.KnownChainIds.AvalancheMainnet:
            return caip_1.avalancheAssetId;
        case types_1.KnownChainIds.ArbitrumNovaMainnet:
            return caip_1.arbitrumNovaAssetId;
        case types_1.KnownChainIds.BaseMainnet:
            return caip_1.baseAssetId;
        case types_1.KnownChainIds.BitcoinCashMainnet:
            return caip_1.bchAssetId;
        case types_1.KnownChainIds.BitcoinMainnet:
            return caip_1.btcAssetId;
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return caip_1.bscAssetId;
        case types_1.KnownChainIds.CosmosMainnet:
            return caip_1.cosmosAssetId;
        case types_1.KnownChainIds.DogecoinMainnet:
            return caip_1.dogeAssetId;
        case types_1.KnownChainIds.EthereumMainnet:
            return caip_1.ethAssetId;
        case types_1.KnownChainIds.GnosisMainnet:
            return caip_1.gnosisAssetId;
        case types_1.KnownChainIds.LitecoinMainnet:
            return caip_1.ltcAssetId;
        case types_1.KnownChainIds.OptimismMainnet:
            return caip_1.optimismAssetId;
        case types_1.KnownChainIds.PolygonMainnet:
            return caip_1.polygonAssetId;
        case types_1.KnownChainIds.ThorchainMainnet:
            return caip_1.thorchainAssetId;
        case types_1.KnownChainIds.SolanaMainnet:
            return caip_1.solAssetId;
        default:
            (0, assertUnreachable_1.assertUnreachable)(chainId);
    }
    throw Error(`Unsupported chainId: ${chainId}`);
};
exports.chainIdToFeeAssetId = chainIdToFeeAssetId;
