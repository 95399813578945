"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StakingToJSON = exports.StakingFromJSONTyped = exports.StakingFromJSON = exports.instanceOfStaking = void 0;
const Delegation_1 = require("./Delegation");
const Redelegation_1 = require("./Redelegation");
const Reward_1 = require("./Reward");
const Unbonding_1 = require("./Unbonding");
/**
 * Check if a given object implements the Staking interface.
 */
function instanceOfStaking(value) {
    let isInstance = true;
    isInstance = isInstance && "delegations" in value;
    isInstance = isInstance && "redelegations" in value;
    isInstance = isInstance && "rewards" in value;
    isInstance = isInstance && "unbondings" in value;
    return isInstance;
}
exports.instanceOfStaking = instanceOfStaking;
function StakingFromJSON(json) {
    return StakingFromJSONTyped(json, false);
}
exports.StakingFromJSON = StakingFromJSON;
function StakingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'delegations': (json['delegations'].map(Delegation_1.DelegationFromJSON)),
        'redelegations': (json['redelegations'].map(Redelegation_1.RedelegationFromJSON)),
        'rewards': (json['rewards'].map(Reward_1.RewardFromJSON)),
        'unbondings': (json['unbondings'].map(Unbonding_1.UnbondingFromJSON)),
    };
}
exports.StakingFromJSONTyped = StakingFromJSONTyped;
function StakingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'delegations': (value.delegations.map(Delegation_1.DelegationToJSON)),
        'redelegations': (value.redelegations.map(Redelegation_1.RedelegationToJSON)),
        'rewards': (value.rewards.map(Reward_1.RewardToJSON)),
        'unbondings': (value.unbondings.map(Unbonding_1.UnbondingToJSON)),
    };
}
exports.StakingToJSON = StakingToJSON;
