"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeoutMonadic = exports.timeout = void 0;
const monads_1 = require("@sniptt/monads");
const timeout = (promise, timeoutMs, fallbackValue) => {
    return Promise.race([
        promise,
        new Promise(resolve => setTimeout(() => {
            resolve(fallbackValue);
        }, timeoutMs)),
    ]);
};
exports.timeout = timeout;
const timeoutMonadic = (promise, timeoutMs, timeoutRight) => {
    return Promise.race([
        promise,
        new Promise(resolve => setTimeout(() => {
            resolve((0, monads_1.Err)(timeoutRight));
        }, timeoutMs)),
    ]);
};
exports.timeoutMonadic = timeoutMonadic;
