"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainflipSwapper = void 0;
const utils_1 = require("../../utils");
const constants_1 = require("./constants");
const helpers_1 = require("./utils/helpers");
exports.chainflipSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    executeSolanaTransaction: utils_1.executeSolanaTransaction,
    executeUtxoTransaction: async (txToSign, { signAndBroadcastTransaction }) => {
        return await signAndBroadcastTransaction(txToSign);
    },
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve(assets
            .filter(asset => constants_1.CHAINFLIP_SUPPORTED_CHAIN_IDS.sell.includes(asset.chainId))
            .filter(asset => (0, helpers_1.isSupportedAssetId)(asset.chainId, asset.assetId))
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve(input.assets
            .filter(asset => constants_1.CHAINFLIP_SUPPORTED_CHAIN_IDS.buy.includes(asset.chainId))
            .filter(asset => (0, helpers_1.isSupportedAssetId)(asset.chainId, asset.assetId))
            .map(asset => asset.assetId));
    },
};
