"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arbitrumBridgeSwapper = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("../../utils");
const constants_1 = require("./utils/constants");
exports.arbitrumBridgeSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve(assets
            .filter(asset => constants_1.ARBITRUM_BRIDGE_SUPPORTED_CHAIN_IDS.sell.includes(asset.chainId))
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        const supportedChainIds = (() => {
            switch (input.sellAsset.chainId) {
                case caip_1.ethChainId:
                    return [caip_1.arbitrumChainId];
                case caip_1.arbitrumChainId:
                    return [caip_1.ethChainId];
                default:
                    return [];
            }
        })();
        return Promise.resolve(input.assets
            .filter(asset => supportedChainIds.includes(asset.chainId))
            .map(asset => asset.assetId));
    },
};
