"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapEventToJSON = exports.SwapEventFromJSONTyped = exports.SwapEventFromJSON = exports.instanceOfSwapEvent = void 0;
const NativeBalanceChange_1 = require("./NativeBalanceChange");
const TokenBalanceChange_1 = require("./TokenBalanceChange");
const TokenSwap_1 = require("./TokenSwap");
/**
 * Check if a given object implements the SwapEvent interface.
 */
function instanceOfSwapEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "nativeInput" in value;
    isInstance = isInstance && "nativeOutput" in value;
    isInstance = isInstance && "tokenInputs" in value;
    isInstance = isInstance && "tokenOutputs" in value;
    isInstance = isInstance && "tokenFees" in value;
    isInstance = isInstance && "nativeFees" in value;
    isInstance = isInstance && "innerSwaps" in value;
    return isInstance;
}
exports.instanceOfSwapEvent = instanceOfSwapEvent;
function SwapEventFromJSON(json) {
    return SwapEventFromJSONTyped(json, false);
}
exports.SwapEventFromJSON = SwapEventFromJSON;
function SwapEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nativeInput': (0, NativeBalanceChange_1.NativeBalanceChangeFromJSON)(json['nativeInput']),
        'nativeOutput': (0, NativeBalanceChange_1.NativeBalanceChangeFromJSON)(json['nativeOutput']),
        'tokenInputs': (json['tokenInputs'].map(TokenBalanceChange_1.TokenBalanceChangeFromJSON)),
        'tokenOutputs': (json['tokenOutputs'].map(TokenBalanceChange_1.TokenBalanceChangeFromJSON)),
        'tokenFees': (json['tokenFees'].map(TokenBalanceChange_1.TokenBalanceChangeFromJSON)),
        'nativeFees': (json['nativeFees'].map(NativeBalanceChange_1.NativeBalanceChangeFromJSON)),
        'innerSwaps': (json['innerSwaps'].map(TokenSwap_1.TokenSwapFromJSON)),
    };
}
exports.SwapEventFromJSONTyped = SwapEventFromJSONTyped;
function SwapEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nativeInput': (0, NativeBalanceChange_1.NativeBalanceChangeToJSON)(value.nativeInput),
        'nativeOutput': (0, NativeBalanceChange_1.NativeBalanceChangeToJSON)(value.nativeOutput),
        'tokenInputs': (value.tokenInputs.map(TokenBalanceChange_1.TokenBalanceChangeToJSON)),
        'tokenOutputs': (value.tokenOutputs.map(TokenBalanceChange_1.TokenBalanceChangeToJSON)),
        'tokenFees': (value.tokenFees.map(TokenBalanceChange_1.TokenBalanceChangeToJSON)),
        'nativeFees': (value.nativeFees.map(NativeBalanceChange_1.NativeBalanceChangeToJSON)),
        'innerSwaps': (value.innerSwaps.map(TokenSwap_1.TokenSwapToJSON)),
    };
}
exports.SwapEventToJSON = SwapEventToJSON;
