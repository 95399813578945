"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLifiChainMap = void 0;
const sdk_1 = require("@lifi/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const configureLiFi_1 = require("./configureLiFi");
const createLifiChainMap_1 = require("./createLifiChainMap/createLifiChainMap");
const getLifiChainMap = async () => {
    (0, configureLiFi_1.configureLiFi)();
    const supportedChainRefs = chain_adapters_1.evmChainIds
        .filter(chainId => chainId !== caip_1.arbitrumNovaChainId)
        .map(chainId => Number((0, caip_1.fromChainId)(chainId).chainReference));
    const chains = await (0, sdk_1.getChains)();
    if (chains === undefined)
        throw Error('no chains available');
    const filteredChains = chains.filter(chain => supportedChainRefs.includes(chain.id));
    return (0, createLifiChainMap_1.createLifiChainMap)(filteredChains);
};
exports.getLifiChainMap = getLifiChainMap;
