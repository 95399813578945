"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionEventToJSON = exports.TransactionEventFromJSONTyped = exports.TransactionEventFromJSON = exports.instanceOfTransactionEvent = void 0;
const CompressedNftEvent_1 = require("./CompressedNftEvent");
const TransactionEventNft_1 = require("./TransactionEventNft");
const TransactionEventSwap_1 = require("./TransactionEventSwap");
/**
 * Check if a given object implements the TransactionEvent interface.
 */
function instanceOfTransactionEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "nft" in value;
    isInstance = isInstance && "swap" in value;
    isInstance = isInstance && "compressed" in value;
    return isInstance;
}
exports.instanceOfTransactionEvent = instanceOfTransactionEvent;
function TransactionEventFromJSON(json) {
    return TransactionEventFromJSONTyped(json, false);
}
exports.TransactionEventFromJSON = TransactionEventFromJSON;
function TransactionEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nft': (0, TransactionEventNft_1.TransactionEventNftFromJSON)(json['nft']),
        'swap': (0, TransactionEventSwap_1.TransactionEventSwapFromJSON)(json['swap']),
        'compressed': (json['compressed'] === null ? null : json['compressed'].map(CompressedNftEvent_1.CompressedNftEventFromJSON)),
    };
}
exports.TransactionEventFromJSONTyped = TransactionEventFromJSONTyped;
function TransactionEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nft': (0, TransactionEventNft_1.TransactionEventNftToJSON)(value.nft),
        'swap': (0, TransactionEventSwap_1.TransactionEventSwapToJSON)(value.swap),
        'compressed': (value.compressed === null ? null : value.compressed.map(CompressedNftEvent_1.CompressedNftEventToJSON)),
    };
}
exports.TransactionEventToJSON = TransactionEventToJSON;
