"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InnerInstructionToJSON = exports.InnerInstructionFromJSONTyped = exports.InnerInstructionFromJSON = exports.instanceOfInnerInstruction = void 0;
/**
 * Check if a given object implements the InnerInstruction interface.
 */
function instanceOfInnerInstruction(value) {
    let isInstance = true;
    isInstance = isInstance && "programId" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "accounts" in value;
    return isInstance;
}
exports.instanceOfInnerInstruction = instanceOfInnerInstruction;
function InnerInstructionFromJSON(json) {
    return InnerInstructionFromJSONTyped(json, false);
}
exports.InnerInstructionFromJSON = InnerInstructionFromJSON;
function InnerInstructionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'programId': json['programId'],
        'data': json['data'],
        'accounts': json['accounts'],
    };
}
exports.InnerInstructionFromJSONTyped = InnerInstructionFromJSONTyped;
function InnerInstructionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'programId': value.programId,
        'data': value.data,
        'accounts': value.accounts,
    };
}
exports.InnerInstructionToJSON = InnerInstructionToJSON;
