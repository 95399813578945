"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIntermediaryTransactionOutputs = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const lifiTokenToAssetId_1 = require("../lifiTokenToAssetId/lifiTokenToAssetId");
const isLifiStep = (step) => step.type === 'lifi';
const getIntermediaryTransactionOutputs = (assets, step) => {
    const tradeSteps = isLifiStep(step) ? step.includedSteps : [step];
    const intermediaryTradeData = tradeSteps.slice(0, -1);
    // exit early if there are no intermediary steps
    if (intermediaryTradeData.length === 0)
        return;
    return intermediaryTradeData
        .map(step => {
        const assetId = (0, lifiTokenToAssetId_1.lifiTokenToAssetId)(step.action.toToken);
        const asset = assets[assetId] ??
            (0, utils_1.makeAsset)(assets, {
                assetId,
                name: step.action.toToken.name,
                symbol: step.action.toToken.symbol,
                precision: step.action.toToken.decimals,
            });
        return {
            asset,
            amountCryptoBaseUnit: step.estimate.toAmountMin,
        };
    })
        .filter(utils_1.isSome);
};
exports.getIntermediaryTransactionOutputs = getIntermediaryTransactionOutputs;
