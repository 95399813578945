"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/optimism-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenMetadataToJSON = exports.TokenMetadataFromJSONTyped = exports.TokenMetadataFromJSON = exports.instanceOfTokenMetadata = void 0;
const TokenMetadataMedia_1 = require("./TokenMetadataMedia");
/**
 * Check if a given object implements the TokenMetadata interface.
 */
function instanceOfTokenMetadata(value) {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "media" in value;
    return isInstance;
}
exports.instanceOfTokenMetadata = instanceOfTokenMetadata;
function TokenMetadataFromJSON(json) {
    return TokenMetadataFromJSONTyped(json, false);
}
exports.TokenMetadataFromJSON = TokenMetadataFromJSON;
function TokenMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': json['description'],
        'media': (0, TokenMetadataMedia_1.TokenMetadataMediaFromJSON)(json['media']),
    };
}
exports.TokenMetadataFromJSONTyped = TokenMetadataFromJSONTyped;
function TokenMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'media': (0, TokenMetadataMedia_1.TokenMetadataMediaToJSON)(value.media),
    };
}
exports.TokenMetadataToJSON = TokenMetadataToJSON;
