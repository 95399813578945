"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain-V1 Unchained API
 * Provides access to thorchain-v1 chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmosSDKAccountAllOfToJSON = exports.CosmosSDKAccountAllOfFromJSONTyped = exports.CosmosSDKAccountAllOfFromJSON = exports.instanceOfCosmosSDKAccountAllOf = void 0;
const Value_1 = require("./Value");
/**
 * Check if a given object implements the CosmosSDKAccountAllOf interface.
 */
function instanceOfCosmosSDKAccountAllOf(value) {
    let isInstance = true;
    isInstance = isInstance && "accountNumber" in value;
    isInstance = isInstance && "assets" in value;
    isInstance = isInstance && "sequence" in value;
    return isInstance;
}
exports.instanceOfCosmosSDKAccountAllOf = instanceOfCosmosSDKAccountAllOf;
function CosmosSDKAccountAllOfFromJSON(json) {
    return CosmosSDKAccountAllOfFromJSONTyped(json, false);
}
exports.CosmosSDKAccountAllOfFromJSON = CosmosSDKAccountAllOfFromJSON;
function CosmosSDKAccountAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountNumber': json['accountNumber'],
        'assets': (json['assets'].map(Value_1.ValueFromJSON)),
        'sequence': json['sequence'],
    };
}
exports.CosmosSDKAccountAllOfFromJSONTyped = CosmosSDKAccountAllOfFromJSONTyped;
function CosmosSDKAccountAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountNumber': value.accountNumber,
        'assets': (value.assets.map(Value_1.ValueToJSON)),
        'sequence': value.sequence,
    };
}
exports.CosmosSDKAccountAllOfToJSON = CosmosSDKAccountAllOfToJSON;
