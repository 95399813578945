"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeTransferToJSON = exports.NativeTransferFromJSONTyped = exports.NativeTransferFromJSON = exports.instanceOfNativeTransfer = void 0;
/**
 * Check if a given object implements the NativeTransfer interface.
 */
function instanceOfNativeTransfer(value) {
    let isInstance = true;
    isInstance = isInstance && "fromUserAccount" in value;
    isInstance = isInstance && "toUserAccount" in value;
    isInstance = isInstance && "amount" in value;
    return isInstance;
}
exports.instanceOfNativeTransfer = instanceOfNativeTransfer;
function NativeTransferFromJSON(json) {
    return NativeTransferFromJSONTyped(json, false);
}
exports.NativeTransferFromJSON = NativeTransferFromJSON;
function NativeTransferFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fromUserAccount': json['fromUserAccount'],
        'toUserAccount': json['toUserAccount'],
        'amount': json['amount'],
    };
}
exports.NativeTransferFromJSONTyped = NativeTransferFromJSONTyped;
function NativeTransferToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fromUserAccount': value.fromUserAccount,
        'toUserAccount': value.toUserAccount,
        'amount': value.amount,
    };
}
exports.NativeTransferToJSON = NativeTransferToJSON;
