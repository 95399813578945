"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/arbitrum-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoRpcRequest200ResponseToJSON = exports.DoRpcRequest200ResponseFromJSONTyped = exports.DoRpcRequest200ResponseFromJSON = exports.instanceOfDoRpcRequest200Response = exports.DoRpcRequest200ResponseJsonrpcEnum = void 0;
const runtime_1 = require("../runtime");
const RPCResponseError_1 = require("./RPCResponseError");
const RPCResponseId_1 = require("./RPCResponseId");
/**
 * @export
 */
exports.DoRpcRequest200ResponseJsonrpcEnum = {
    _20: '2.0'
};
/**
 * Check if a given object implements the DoRpcRequest200Response interface.
 */
function instanceOfDoRpcRequest200Response(value) {
    let isInstance = true;
    isInstance = isInstance && "jsonrpc" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
exports.instanceOfDoRpcRequest200Response = instanceOfDoRpcRequest200Response;
function DoRpcRequest200ResponseFromJSON(json) {
    return DoRpcRequest200ResponseFromJSONTyped(json, false);
}
exports.DoRpcRequest200ResponseFromJSON = DoRpcRequest200ResponseFromJSON;
function DoRpcRequest200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'jsonrpc': json['jsonrpc'],
        'id': (0, RPCResponseId_1.RPCResponseIdFromJSON)(json['id']),
        'result': !(0, runtime_1.exists)(json, 'result') ? undefined : json['result'],
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, RPCResponseError_1.RPCResponseErrorFromJSON)(json['error']),
    };
}
exports.DoRpcRequest200ResponseFromJSONTyped = DoRpcRequest200ResponseFromJSONTyped;
function DoRpcRequest200ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'jsonrpc': value.jsonrpc,
        'id': (0, RPCResponseId_1.RPCResponseIdToJSON)(value.id),
        'result': value.result,
        'error': (0, RPCResponseError_1.RPCResponseErrorToJSON)(value.error),
    };
}
exports.DoRpcRequest200ResponseToJSON = DoRpcRequest200ResponseToJSON;
