"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompressedNftEventToJSON = exports.CompressedNftEventFromJSONTyped = exports.CompressedNftEventFromJSON = exports.instanceOfCompressedNftEvent = void 0;
const CompressedNftEventMetadata_1 = require("./CompressedNftEventMetadata");
const TransactionType_1 = require("./TransactionType");
/**
 * Check if a given object implements the CompressedNftEvent interface.
 */
function instanceOfCompressedNftEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "treeId" in value;
    isInstance = isInstance && "leafIndex" in value;
    isInstance = isInstance && "seq" in value;
    isInstance = isInstance && "assetId" in value;
    isInstance = isInstance && "instructionIndex" in value;
    isInstance = isInstance && "innerInstructionIndex" in value;
    isInstance = isInstance && "newLeafOwner" in value;
    isInstance = isInstance && "oldLeafOwner" in value;
    isInstance = isInstance && "newLeafDelegate" in value;
    isInstance = isInstance && "oldLeafDelegate" in value;
    isInstance = isInstance && "treeDelegate" in value;
    isInstance = isInstance && "metadata" in value;
    return isInstance;
}
exports.instanceOfCompressedNftEvent = instanceOfCompressedNftEvent;
function CompressedNftEventFromJSON(json) {
    return CompressedNftEventFromJSONTyped(json, false);
}
exports.CompressedNftEventFromJSON = CompressedNftEventFromJSON;
function CompressedNftEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TransactionType_1.TransactionTypeFromJSON)(json['type']),
        'treeId': json['treeId'],
        'leafIndex': json['leafIndex'],
        'seq': json['seq'],
        'assetId': json['assetId'],
        'instructionIndex': json['instructionIndex'],
        'innerInstructionIndex': json['innerInstructionIndex'],
        'newLeafOwner': json['newLeafOwner'],
        'oldLeafOwner': json['oldLeafOwner'],
        'newLeafDelegate': json['newLeafDelegate'],
        'oldLeafDelegate': json['oldLeafDelegate'],
        'treeDelegate': json['treeDelegate'],
        'metadata': (0, CompressedNftEventMetadata_1.CompressedNftEventMetadataFromJSON)(json['metadata']),
    };
}
exports.CompressedNftEventFromJSONTyped = CompressedNftEventFromJSONTyped;
function CompressedNftEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TransactionType_1.TransactionTypeToJSON)(value.type),
        'treeId': value.treeId,
        'leafIndex': value.leafIndex,
        'seq': value.seq,
        'assetId': value.assetId,
        'instructionIndex': value.instructionIndex,
        'innerInstructionIndex': value.innerInstructionIndex,
        'newLeafOwner': value.newLeafOwner,
        'oldLeafOwner': value.oldLeafOwner,
        'newLeafDelegate': value.newLeafDelegate,
        'oldLeafDelegate': value.oldLeafDelegate,
        'treeDelegate': value.treeDelegate,
        'metadata': (0, CompressedNftEventMetadata_1.CompressedNftEventMetadataToJSON)(value.metadata),
    };
}
exports.CompressedNftEventToJSON = CompressedNftEventToJSON;
