"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInboundAddressDataForChain = void 0;
const monads_1 = require("@sniptt/monads");
const poolAssetHelpers_1 = require("../swappers/ThorchainSwapper/utils/poolAssetHelpers/poolAssetHelpers");
const thorService_1 = require("../swappers/ThorchainSwapper/utils/thorService");
const types_1 = require("../types");
const utils_1 = require("../utils");
const getInboundAddressDataForChain = async (daemonUrl, assetId, excludeHalted = true) => {
    if (!assetId)
        return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: '[getInboundAddressDataForChain]: AssetId is required',
            code: types_1.TradeQuoteError.InternalError,
        }));
    const assetPoolId = (0, poolAssetHelpers_1.assetIdToPoolAssetId)({ assetId });
    const assetChainSymbol = assetPoolId?.slice(0, assetPoolId.indexOf('.'));
    return (await thorService_1.thorService.get(`${daemonUrl}/lcd/thorchain/inbound_addresses`))
        .andThen(({ data: inboundAddresses }) => {
        const activeInboundAddresses = inboundAddresses.filter(a => !a.halted);
        return (0, monads_1.Ok)((excludeHalted ? activeInboundAddresses : inboundAddresses).find(inbound => inbound.chain === assetChainSymbol));
    })
        .andThen(maybeInboundAddressResponse => {
        if (!maybeInboundAddressResponse ||
            // We should not need this. Added to conform to the previous inboundAddress?.address by consumers
            // but the address itself will never be undefined, only the inboundAddress
            (maybeInboundAddressResponse && !maybeInboundAddressResponse.address))
            return (0, monads_1.Err)((0, utils_1.makeSwapErrorRight)({
                message: `[getInboundAddressDataForChain]: No inbound address found for asset ${assetId}`,
                code: types_1.TradeQuoteError.QueryFailed,
            }));
        return (0, monads_1.Ok)(maybeInboundAddressResponse);
    });
};
exports.getInboundAddressDataForChain = getInboundAddressDataForChain;
