"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RPCResponseIdToJSON = exports.RPCResponseIdFromJSONTyped = exports.RPCResponseIdFromJSON = exports.instanceOfRPCResponseId = void 0;
/**
 * Check if a given object implements the RPCResponseId interface.
 */
function instanceOfRPCResponseId(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfRPCResponseId = instanceOfRPCResponseId;
function RPCResponseIdFromJSON(json) {
    return RPCResponseIdFromJSONTyped(json, false);
}
exports.RPCResponseIdFromJSON = RPCResponseIdFromJSON;
function RPCResponseIdFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RPCResponseIdFromJSONTyped = RPCResponseIdFromJSONTyped;
function RPCResponseIdToJSON(value) {
    return value;
}
exports.RPCResponseIdToJSON = RPCResponseIdToJSON;
