"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/polygon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalTxToJSON = exports.InternalTxFromJSONTyped = exports.InternalTxFromJSON = exports.instanceOfInternalTx = void 0;
/**
 * Check if a given object implements the InternalTx interface.
 */
function instanceOfInternalTx(value) {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
exports.instanceOfInternalTx = instanceOfInternalTx;
function InternalTxFromJSON(json) {
    return InternalTxFromJSONTyped(json, false);
}
exports.InternalTxFromJSON = InternalTxFromJSON;
function InternalTxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': json['from'],
        'to': json['to'],
        'value': json['value'],
    };
}
exports.InternalTxFromJSONTyped = InternalTxFromJSONTyped;
function InternalTxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from,
        'to': value.to,
        'value': value.value,
    };
}
exports.InternalTxToJSON = InternalTxToJSON;
