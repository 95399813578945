"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstimateFeesBodyAnyOfToJSON = exports.EstimateFeesBodyAnyOfFromJSONTyped = exports.EstimateFeesBodyAnyOfFromJSON = exports.instanceOfEstimateFeesBodyAnyOf = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the EstimateFeesBodyAnyOf interface.
 */
function instanceOfEstimateFeesBodyAnyOf(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfEstimateFeesBodyAnyOf = instanceOfEstimateFeesBodyAnyOf;
function EstimateFeesBodyAnyOfFromJSON(json) {
    return EstimateFeesBodyAnyOfFromJSONTyped(json, false);
}
exports.EstimateFeesBodyAnyOfFromJSON = EstimateFeesBodyAnyOfFromJSON;
function EstimateFeesBodyAnyOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'serializedTx': !(0, runtime_1.exists)(json, 'serializedTx') ? undefined : json['serializedTx'],
    };
}
exports.EstimateFeesBodyAnyOfFromJSONTyped = EstimateFeesBodyAnyOfFromJSONTyped;
function EstimateFeesBodyAnyOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'serializedTx': value.serializedTx,
    };
}
exports.EstimateFeesBodyAnyOfToJSON = EstimateFeesBodyAnyOfToJSON;
