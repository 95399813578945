"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/base-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenMetadataMediaToJSON = exports.TokenMetadataMediaFromJSONTyped = exports.TokenMetadataMediaFromJSON = exports.instanceOfTokenMetadataMedia = exports.TokenMetadataMediaTypeEnum = void 0;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.TokenMetadataMediaTypeEnum = {
    Image: 'image',
    Video: 'video'
};
/**
 * Check if a given object implements the TokenMetadataMedia interface.
 */
function instanceOfTokenMetadataMedia(value) {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
exports.instanceOfTokenMetadataMedia = instanceOfTokenMetadataMedia;
function TokenMetadataMediaFromJSON(json) {
    return TokenMetadataMediaFromJSONTyped(json, false);
}
exports.TokenMetadataMediaFromJSON = TokenMetadataMediaFromJSON;
function TokenMetadataMediaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'url': json['url'],
    };
}
exports.TokenMetadataMediaFromJSONTyped = TokenMetadataMediaFromJSONTyped;
function TokenMetadataMediaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'url': value.url,
    };
}
exports.TokenMetadataMediaToJSON = TokenMetadataMediaToJSON;
