"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgramInfoToJSON = exports.ProgramInfoFromJSONTyped = exports.ProgramInfoFromJSON = exports.instanceOfProgramInfo = void 0;
const ProgramName_1 = require("./ProgramName");
const Source_1 = require("./Source");
/**
 * Check if a given object implements the ProgramInfo interface.
 */
function instanceOfProgramInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "programName" in value;
    isInstance = isInstance && "instructionName" in value;
    return isInstance;
}
exports.instanceOfProgramInfo = instanceOfProgramInfo;
function ProgramInfoFromJSON(json) {
    return ProgramInfoFromJSONTyped(json, false);
}
exports.ProgramInfoFromJSON = ProgramInfoFromJSON;
function ProgramInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'source': (0, Source_1.SourceFromJSON)(json['source']),
        'account': json['account'],
        'programName': (0, ProgramName_1.ProgramNameFromJSON)(json['programName']),
        'instructionName': json['instructionName'],
    };
}
exports.ProgramInfoFromJSONTyped = ProgramInfoFromJSONTyped;
function ProgramInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'source': (0, Source_1.SourceToJSON)(value.source),
        'account': value.account,
        'programName': (0, ProgramName_1.ProgramNameToJSON)(value.programName),
        'instructionName': value.instructionName,
    };
}
exports.ProgramInfoToJSON = ProgramInfoToJSON;
