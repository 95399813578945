"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoincash-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressToJSON = exports.AddressFromJSONTyped = exports.AddressFromJSON = exports.instanceOfAddress = void 0;
/**
 * Check if a given object implements the Address interface.
 */
function instanceOfAddress(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "pubkey" in value;
    return isInstance;
}
exports.instanceOfAddress = instanceOfAddress;
function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
exports.AddressFromJSON = AddressFromJSON;
function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'pubkey': json['pubkey'],
    };
}
exports.AddressFromJSONTyped = AddressFromJSONTyped;
function AddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'pubkey': value.pubkey,
    };
}
exports.AddressToJSON = AddressToJSON;
