"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnbondingEntryToJSON = exports.UnbondingEntryFromJSONTyped = exports.UnbondingEntryFromJSON = exports.instanceOfUnbondingEntry = void 0;
const Value_1 = require("./Value");
/**
 * Check if a given object implements the UnbondingEntry interface.
 */
function instanceOfUnbondingEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "completionTime" in value;
    return isInstance;
}
exports.instanceOfUnbondingEntry = instanceOfUnbondingEntry;
function UnbondingEntryFromJSON(json) {
    return UnbondingEntryFromJSONTyped(json, false);
}
exports.UnbondingEntryFromJSON = UnbondingEntryFromJSON;
function UnbondingEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': (0, Value_1.ValueFromJSON)(json['balance']),
        'completionTime': json['completionTime'],
    };
}
exports.UnbondingEntryFromJSONTyped = UnbondingEntryFromJSONTyped;
function UnbondingEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': (0, Value_1.ValueToJSON)(value.balance),
        'completionTime': value.completionTime,
    };
}
exports.UnbondingEntryToJSON = UnbondingEntryToJSON;
