"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxToJSON = exports.TxFromJSONTyped = exports.TxFromJSON = exports.instanceOfTx = void 0;
const runtime_1 = require("../runtime");
const Message_1 = require("./Message");
const Value_1 = require("./Value");
/**
 * Check if a given object implements the Tx interface.
 */
function instanceOfTx(value) {
    let isInstance = true;
    isInstance = isInstance && "blockHeight" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "txid" in value;
    isInstance = isInstance && "confirmations" in value;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "gasUsed" in value;
    isInstance = isInstance && "gasWanted" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
exports.instanceOfTx = instanceOfTx;
function TxFromJSON(json) {
    return TxFromJSONTyped(json, false);
}
exports.TxFromJSON = TxFromJSON;
function TxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockHash': !(0, runtime_1.exists)(json, 'blockHash') ? undefined : json['blockHash'],
        'blockHeight': json['blockHeight'],
        'timestamp': json['timestamp'],
        'txid': json['txid'],
        'confirmations': json['confirmations'],
        'events': json['events'],
        'fee': (0, Value_1.ValueFromJSON)(json['fee']),
        'gasUsed': json['gasUsed'],
        'gasWanted': json['gasWanted'],
        'index': json['index'],
        'memo': !(0, runtime_1.exists)(json, 'memo') ? undefined : json['memo'],
        'messages': (json['messages'].map(Message_1.MessageFromJSON)),
        'value': json['value'],
    };
}
exports.TxFromJSONTyped = TxFromJSONTyped;
function TxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockHash': value.blockHash,
        'blockHeight': value.blockHeight,
        'timestamp': value.timestamp,
        'txid': value.txid,
        'confirmations': value.confirmations,
        'events': value.events,
        'fee': (0, Value_1.ValueToJSON)(value.fee),
        'gasUsed': value.gasUsed,
        'gasWanted': value.gasWanted,
        'index': value.index,
        'memo': value.memo,
        'messages': (value.messages.map(Message_1.MessageToJSON)),
        'value': value.value,
    };
}
exports.TxToJSON = TxToJSON;
