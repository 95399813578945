"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIFI_SHARED_FEES_STEP_NAME = exports.LIFI_SUPPORTED_CHAIN_IDS = exports.LIFI_INTEGRATOR_ID = exports.L1_FEE_CHAIN_IDS = exports.L1_GAS_ORACLE_ADDRESS = exports.DEFAULT_LIFI_TOKEN_ADDRESS = exports.SELECTED_ROUTE_INDEX = exports.MIN_SAME_CHAIN_AMOUNT_THRESHOLD_USD_HUMAN = exports.MIN_CROSS_CHAIN_AMOUNT_THRESHOLD_USD_HUMAN = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const types_1 = require("@shapeshiftoss/types");
exports.MIN_CROSS_CHAIN_AMOUNT_THRESHOLD_USD_HUMAN = 20; // arbitrary amount deemed by lifi devs to meet minimum amount across all brdiges
exports.MIN_SAME_CHAIN_AMOUNT_THRESHOLD_USD_HUMAN = '0.01'; // Same chain bridges can be of any amount, since fees are paid explicitly as miner fees in one chain
exports.SELECTED_ROUTE_INDEX = 0; // default to first route - this is the highest ranking according to the query we send
exports.DEFAULT_LIFI_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';
exports.L1_GAS_ORACLE_ADDRESS = '0x420000000000000000000000000000000000000f'; // optimism & base
exports.L1_FEE_CHAIN_IDS = [types_1.KnownChainIds.OptimismMainnet, types_1.KnownChainIds.BaseMainnet];
// used for analytics and affiliate fee - do not change this without considering impact
exports.LIFI_INTEGRATOR_ID = 'shapeshift';
exports.LIFI_SUPPORTED_CHAIN_IDS = {
    sell: chain_adapters_1.evmChainIds,
    buy: chain_adapters_1.evmChainIds,
};
exports.LIFI_SHARED_FEES_STEP_NAME = 'LIFI Shared Fee';
