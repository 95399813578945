"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cowService = void 0;
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const maxAge = 5 * 1000; // 5 seconds
const cachedUrls = ['/mainnet/api/v1/quote', '/xdai/api/v1/quote'];
const axiosConfig = {
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const cowServiceBase = (0, utils_1.createCache)(maxAge, cachedUrls, axiosConfig);
exports.cowService = (0, utils_1.makeSwapperAxiosServiceMonadic)(cowServiceBase, types_1.SwapperName.CowSwap);
