"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeBalanceChangeToJSON = exports.NativeBalanceChangeFromJSONTyped = exports.NativeBalanceChangeFromJSON = exports.instanceOfNativeBalanceChange = void 0;
/**
 * Check if a given object implements the NativeBalanceChange interface.
 */
function instanceOfNativeBalanceChange(value) {
    let isInstance = true;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "amount" in value;
    return isInstance;
}
exports.instanceOfNativeBalanceChange = instanceOfNativeBalanceChange;
function NativeBalanceChangeFromJSON(json) {
    return NativeBalanceChangeFromJSONTyped(json, false);
}
exports.NativeBalanceChangeFromJSON = NativeBalanceChangeFromJSON;
function NativeBalanceChangeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'account': json['account'],
        'amount': json['amount'],
    };
}
exports.NativeBalanceChangeFromJSONTyped = NativeBalanceChangeFromJSONTyped;
function NativeBalanceChangeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'account': value.account,
        'amount': value.amount,
    };
}
exports.NativeBalanceChangeToJSON = NativeBalanceChangeToJSON;
