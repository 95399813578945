"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lifiChainIdToChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const lifiChainIdToChainId = (lifiChainId) => {
    return (0, caip_1.toChainId)({
        chainNamespace: caip_1.CHAIN_NAMESPACE.Evm,
        chainReference: lifiChainId.toString(),
    });
};
exports.lifiChainIdToChainId = lifiChainIdToChainId;
