"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const thorchain_1 = require("../../parser/thorchain");
class Parser {
    constructor(args) {
        this.thorchainParser = new thorchain_1.Parser({ midgardUrl: args.midgardUrl });
    }
    async parse(tx) {
        if (!tx.memo)
            return;
        return await this.thorchainParser.parse(tx.memo);
    }
}
exports.Parser = Parser;
