"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriorityFeesToJSON = exports.PriorityFeesFromJSONTyped = exports.PriorityFeesFromJSON = exports.instanceOfPriorityFees = void 0;
/**
 * Check if a given object implements the PriorityFees interface.
 */
function instanceOfPriorityFees(value) {
    let isInstance = true;
    isInstance = isInstance && "baseFee" in value;
    isInstance = isInstance && "slow" in value;
    isInstance = isInstance && "average" in value;
    isInstance = isInstance && "fast" in value;
    return isInstance;
}
exports.instanceOfPriorityFees = instanceOfPriorityFees;
function PriorityFeesFromJSON(json) {
    return PriorityFeesFromJSONTyped(json, false);
}
exports.PriorityFeesFromJSON = PriorityFeesFromJSON;
function PriorityFeesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'baseFee': json['baseFee'],
        'slow': json['slow'],
        'average': json['average'],
        'fast': json['fast'],
    };
}
exports.PriorityFeesFromJSONTyped = PriorityFeesFromJSONTyped;
function PriorityFeesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'baseFee': value.baseFee,
        'slow': value.slow,
        'average': value.average,
        'fast': value.fast,
    };
}
exports.PriorityFeesToJSON = PriorityFeesToJSON;
