"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RFOX_ABI = void 0;
exports.RFOX_ABI = [
    { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
    {
        type: 'function',
        inputs: [],
        name: 'REWARD_RATE',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'UPGRADE_INTERFACE_VERSION',
        outputs: [{ name: '', internalType: 'string', type: 'string' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'WAD',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'total', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'cooldownPeriod',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
        name: 'earned',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [
            { name: 'account', internalType: 'address', type: 'address' },
            { name: 'index', internalType: 'uint256', type: 'uint256' },
        ],
        name: 'getUnstakingRequest',
        outputs: [
            {
                name: '',
                internalType: 'struct UnstakingRequest',
                type: 'tuple',
                components: [
                    {
                        name: 'unstakingBalance',
                        internalType: 'uint256',
                        type: 'uint256',
                    },
                    { name: 'cooldownExpiry', internalType: 'uint256', type: 'uint256' },
                ],
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
        name: 'getUnstakingRequestCount',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'stakingTokenAddress', internalType: 'address', type: 'address' }],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'lastUpdateTimestamp',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'owner',
        outputs: [{ name: '', internalType: 'address', type: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'pause',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'pauseStaking',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'pauseUnstaking',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'pauseWithdrawals',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'paused',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'proxiableUUID',
        outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'newCooldownPeriod', internalType: 'uint256', type: 'uint256' }],
        name: 'setCooldownPeriod',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [{ name: 'runeAddress', internalType: 'string', type: 'string' }],
        name: 'setRuneAddress',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [
            { name: 'amount', internalType: 'uint256', type: 'uint256' },
            { name: 'runeAddress', internalType: 'string', type: 'string' },
        ],
        name: 'stake',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [{ name: '', internalType: 'address', type: 'address' }],
        name: 'stakingInfo',
        outputs: [
            { name: 'stakingBalance', internalType: 'uint256', type: 'uint256' },
            { name: 'unstakingBalance', internalType: 'uint256', type: 'uint256' },
            { name: 'earnedRewards', internalType: 'uint256', type: 'uint256' },
            {
                name: 'rewardPerTokenStored',
                internalType: 'uint256',
                type: 'uint256',
            },
            { name: 'runeAddress', internalType: 'string', type: 'string' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'stakingPaused',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'stakingToken',
        outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'totalCoolingDown',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [],
        name: 'totalStaked',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'unpause',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'unpauseStaking',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'unpauseUnstaking',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'unpauseWithdrawals',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
        name: 'unstake',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'unstakingPaused',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [
            { name: 'newImplementation', internalType: 'address', type: 'address' },
            { name: 'data', internalType: 'bytes', type: 'bytes' },
        ],
        name: 'upgradeToAndCall',
        outputs: [],
        stateMutability: 'payable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'version',
        outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        inputs: [],
        name: 'withdrawalsPaused',
        outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'version',
                internalType: 'uint64',
                type: 'uint64',
                indexed: false,
            },
        ],
        name: 'Initialized',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'previousOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'newOwner',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'OwnershipTransferred',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'Paused',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'oldRuneAddress',
                internalType: 'string',
                type: 'string',
                indexed: true,
            },
            {
                name: 'newRuneAddress',
                internalType: 'string',
                type: 'string',
                indexed: true,
            },
        ],
        name: 'SetRuneAddress',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'runeAddress',
                internalType: 'string',
                type: 'string',
                indexed: true,
            },
        ],
        name: 'Stake',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [{ name: 'isPaused', internalType: 'bool', type: 'bool', indexed: false }],
        name: 'StakingPausedChanged',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: false,
            },
        ],
        name: 'Unpaused',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
            {
                name: 'cooldownExpiry',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'Unstake',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [{ name: 'isPaused', internalType: 'bool', type: 'bool', indexed: false }],
        name: 'UnstakingPausedChanged',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'newCooldownPeriod',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'UpdateCooldownPeriod',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'implementation',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
        ],
        name: 'Upgraded',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [
            {
                name: 'account',
                internalType: 'address',
                type: 'address',
                indexed: true,
            },
            {
                name: 'amount',
                internalType: 'uint256',
                type: 'uint256',
                indexed: false,
            },
        ],
        name: 'Withdraw',
    },
    {
        type: 'event',
        anonymous: false,
        inputs: [{ name: 'isPaused', internalType: 'bool', type: 'bool', indexed: false }],
        name: 'WithdrawalsPausedChanged',
    },
    {
        type: 'error',
        inputs: [{ name: 'target', internalType: 'address', type: 'address' }],
        name: 'AddressEmptyCode',
    },
    {
        type: 'error',
        inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
        name: 'AddressInsufficientBalance',
    },
    {
        type: 'error',
        inputs: [{ name: 'implementation', internalType: 'address', type: 'address' }],
        name: 'ERC1967InvalidImplementation',
    },
    { type: 'error', inputs: [], name: 'ERC1967NonPayable' },
    { type: 'error', inputs: [], name: 'EnforcedPause' },
    { type: 'error', inputs: [], name: 'ExpectedPause' },
    { type: 'error', inputs: [], name: 'FailedInnerCall' },
    { type: 'error', inputs: [], name: 'InvalidInitialization' },
    { type: 'error', inputs: [], name: 'NotInitializing' },
    {
        type: 'error',
        inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
        name: 'OwnableInvalidOwner',
    },
    {
        type: 'error',
        inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
        name: 'OwnableUnauthorizedAccount',
    },
    { type: 'error', inputs: [], name: 'ReentrancyGuardReentrantCall' },
    {
        type: 'error',
        inputs: [{ name: 'token', internalType: 'address', type: 'address' }],
        name: 'SafeERC20FailedOperation',
    },
    { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
    {
        type: 'error',
        inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
        name: 'UUPSUnsupportedProxiableUUID',
    },
];
