"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgramNameToJSON = exports.ProgramNameFromJSONTyped = exports.ProgramNameFromJSON = exports.ProgramName = void 0;
/**
 *
 * @export
 */
exports.ProgramName = {
    Unknown: 'UNKNOWN',
    JupiterV1: 'JUPITER_V1',
    JupiterV2: 'JUPITER_V2',
    JupiterV3: 'JUPITER_V3',
    JupiterV4: 'JUPITER_V4',
    MercurialStableSwap: 'MERCURIAL_STABLE_SWAP',
    SaberStableSwap: 'SABER_STABLE_SWAP',
    SaberExchange: 'SABER_EXCHANGE',
    SerumDexV1: 'SERUM_DEX_V1',
    SerumDexV2: 'SERUM_DEX_V2',
    SerumDexV3: 'SERUM_DEX_V3',
    SerumSwap: 'SERUM_SWAP',
    StepFinance: 'STEP_FINANCE',
    Cropper: 'CROPPER',
    RaydiumLiquidityPoolV2: 'RAYDIUM_LIQUIDITY_POOL_V2',
    RaydiumLiquidityPoolV3: 'RAYDIUM_LIQUIDITY_POOL_V3',
    RaydiumLiquidityPoolV4: 'RAYDIUM_LIQUIDITY_POOL_V4',
    AldrinAmmV1: 'ALDRIN_AMM_V1',
    AldrinAmmV2: 'ALDRIN_AMM_V2',
    Crema: 'CREMA',
    Lifinity: 'LIFINITY',
    LifinityV2: 'LIFINITY_V2',
    Cykura: 'CYKURA',
    OrcaTokenSwapV1: 'ORCA_TOKEN_SWAP_V1',
    OrcaTokenSwapV2: 'ORCA_TOKEN_SWAP_V2',
    OrcaWhirlpools: 'ORCA_WHIRLPOOLS',
    Marinade: 'MARINADE',
    Stepn: 'STEPN',
    SenchaExchange: 'SENCHA_EXCHANGE',
    SarosAmm: 'SAROS_AMM',
    FoxyStake: 'FOXY_STAKE',
    FoxySwap: 'FOXY_SWAP',
    FoxyRaffle: 'FOXY_RAFFLE',
    FoxyTokenMarket: 'FOXY_TOKEN_MARKET',
    FoxyMissions: 'FOXY_MISSIONS',
    FoxyMarmalade: 'FOXY_MARMALADE',
    FoxyCoinflip: 'FOXY_COINFLIP',
    FoxyAuction: 'FOXY_AUCTION',
    Citrus: 'CITRUS',
    HadeSwap: 'HADE_SWAP',
    Zeta: 'ZETA',
    CardinalRent: 'CARDINAL_RENT',
    CardinalStaking: 'CARDINAL_STAKING',
    SharkyFi: 'SHARKY_FI',
    OpenCreatorProtocol: 'OPEN_CREATOR_PROTOCOL',
    Bubblegum: 'BUBBLEGUM',
    CoralCube: 'CORAL_CUBE'
};
function ProgramNameFromJSON(json) {
    return ProgramNameFromJSONTyped(json, false);
}
exports.ProgramNameFromJSON = ProgramNameFromJSON;
function ProgramNameFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ProgramNameFromJSONTyped = ProgramNameFromJSONTyped;
function ProgramNameToJSON(value) {
    return value;
}
exports.ProgramNameToJSON = ProgramNameToJSON;
