"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterCrossChainEvmBuyAssetsBySellAssetId = exports.filterSameChainEvmBuyAssetsBySellAssetId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const _filterEvmBuyAssetsBySellAssetId = ({ assets, sellAsset }, chainIdPredicate) => {
    // evm only
    if (!(0, chain_adapters_1.isEvmChainId)(sellAsset.chainId))
        return [];
    return assets.filter(buyAsset => {
        // evm only AND chain id predicate with no arbitrum nova support for any swappers
        return ((0, chain_adapters_1.isEvmChainId)(buyAsset.chainId) &&
            chainIdPredicate(buyAsset.chainId, sellAsset.chainId) &&
            buyAsset.chainId !== caip_1.arbitrumNovaChainId);
    });
};
const filterSameChainEvmBuyAssetsBySellAssetId = (input) => {
    const sameChainIdPredicate = (buyAssetChainId, sellAssetChainId) => buyAssetChainId === sellAssetChainId;
    return _filterEvmBuyAssetsBySellAssetId(input, sameChainIdPredicate);
};
exports.filterSameChainEvmBuyAssetsBySellAssetId = filterSameChainEvmBuyAssetsBySellAssetId;
const filterCrossChainEvmBuyAssetsBySellAssetId = (input) => {
    const crossChainIdPredicate = (buyAssetChainId, sellAssetChainId) => buyAssetChainId !== sellAssetChainId;
    return _filterEvmBuyAssetsBySellAssetId(input, crossChainIdPredicate);
};
exports.filterCrossChainEvmBuyAssetsBySellAssetId = filterCrossChainEvmBuyAssetsBySellAssetId;
