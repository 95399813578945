"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thorService = void 0;
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
// Important: maxAge should be small because inbound address info must be recent
const maxAge = 5 * 1000; // 5 seconds
const cachedUrls = [
    '/lcd/thorchain/pools',
    '/lcd/thorchain/inbound_addresses',
    '/lcd/thorchain/pool/',
    '/v2/pools',
    '/v2/pool/',
];
const axiosConfig = {
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const thorServiceBase = (0, utils_1.createCache)(maxAge, cachedUrls, axiosConfig);
exports.thorService = (0, utils_1.makeSwapperAxiosServiceMonadic)(thorServiceBase, types_1.SwapperName.Thorchain);
