"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain-V1 Unchained API
 * Provides access to thorchain-v1 chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageToJSON = exports.MessageFromJSONTyped = exports.MessageFromJSON = exports.instanceOfMessage = void 0;
const Value_1 = require("./Value");
/**
 * Check if a given object implements the Message interface.
 */
function instanceOfMessage(value) {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
exports.instanceOfMessage = instanceOfMessage;
function MessageFromJSON(json) {
    return MessageFromJSONTyped(json, false);
}
exports.MessageFromJSON = MessageFromJSON;
function MessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': json['from'],
        'index': json['index'],
        'origin': json['origin'],
        'to': json['to'],
        'type': json['type'],
        'value': (0, Value_1.ValueFromJSON)(json['value']),
    };
}
exports.MessageFromJSONTyped = MessageFromJSONTyped;
function MessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from,
        'index': value.index,
        'origin': value.origin,
        'to': value.to,
        'type': value.type,
        'value': (0, Value_1.ValueToJSON)(value.value),
    };
}
exports.MessageToJSON = MessageToJSON;
