"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAffiliateAppDataFragmentByChainId = exports.getFullAppData = exports.getValuesFromQuoteResponse = exports.deductSlippageFromAmount = exports.deductAffiliateFeesFromAmount = exports.assertValidTrade = exports.domain = exports.getSignTypeDataPayload = exports.getNowPlusThirtyMinutesTimestamp = exports.getCowswapNetwork = exports.ORDER_TYPE_FIELDS = void 0;
const app_data_1 = require("@cowprotocol/app-data");
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const viem_1 = require("viem");
const types_2 = require("../../../../types");
const utils_2 = require("../../../../utils");
const helpers_1 = require("../../../utils/helpers/helpers");
const types_3 = require("../../types");
exports.ORDER_TYPE_FIELDS = [
    { name: 'sellToken', type: 'address' },
    { name: 'buyToken', type: 'address' },
    { name: 'receiver', type: 'address' },
    { name: 'sellAmount', type: 'uint256' },
    { name: 'buyAmount', type: 'uint256' },
    { name: 'validTo', type: 'uint32' },
    { name: 'appData', type: 'bytes32' },
    { name: 'feeAmount', type: 'uint256' },
    { name: 'kind', type: 'string' },
    { name: 'partiallyFillable', type: 'bool' },
    { name: 'sellTokenBalance', type: 'string' },
    { name: 'buyTokenBalance', type: 'string' },
];
const getCowswapNetwork = (chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return (0, monads_1.Ok)(types_3.CowNetwork.Mainnet);
        case types_1.KnownChainIds.GnosisMainnet:
            return (0, monads_1.Ok)(types_3.CowNetwork.Xdai);
        case types_1.KnownChainIds.ArbitrumMainnet:
            return (0, monads_1.Ok)(types_3.CowNetwork.ArbitrumOne);
        default:
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                message: '[getCowswapNetwork]',
                code: types_2.TradeQuoteError.UnsupportedChain,
            }));
    }
};
exports.getCowswapNetwork = getCowswapNetwork;
const getNowPlusThirtyMinutesTimestamp = () => {
    const ts = new Date();
    ts.setMinutes(ts.getMinutes() + 30);
    return Math.round(ts.getTime() / 1000);
};
exports.getNowPlusThirtyMinutesTimestamp = getNowPlusThirtyMinutesTimestamp;
const getSignTypeDataPayload = (domain, order) => {
    return {
        // Mismatch of types between ethers' TypedDataDomain and TypedData :shrugs:
        domain: domain,
        primaryType: 'Order',
        types: {
            Order: exports.ORDER_TYPE_FIELDS,
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
        },
        message: order,
    };
};
exports.getSignTypeDataPayload = getSignTypeDataPayload;
const domain = (chainId, verifyingContract) => {
    return {
        name: 'Gnosis Protocol',
        version: 'v2',
        chainId,
        verifyingContract,
    };
};
exports.domain = domain;
const assertValidTrade = ({ buyAsset, sellAsset, supportedChainIds, }) => {
    if (!supportedChainIds.includes(sellAsset.chainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[CowSwap: assertValidTrade] - unsupported chainId`,
            code: types_2.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAsset.chainId !== buyAsset.chainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[CowSwap: assertValidTrade] - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_2.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    if ((0, caip_1.fromAssetId)(sellAsset.assetId).assetNamespace !== 'erc20') {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: '[CowSwap: assertValidTrade] - Sell asset must be an ERC-20',
            code: types_2.TradeQuoteError.UnsupportedTradePair,
            details: { sellAsset },
        }));
    }
    return (0, monads_1.Ok)(true);
};
exports.assertValidTrade = assertValidTrade;
const deductAffiliateFeesFromAmount = ({ amount, affiliateBps, }) => {
    const hasAffiliateFee = (0, utils_1.bnOrZero)(affiliateBps).gt(0);
    if (!hasAffiliateFee)
        return amount;
    return (0, utils_1.bn)(amount)
        .times((0, utils_1.bn)(1).minus((0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps)))
        .toFixed(0);
};
exports.deductAffiliateFeesFromAmount = deductAffiliateFeesFromAmount;
const deductSlippageFromAmount = ({ amount, slippageTolerancePercentageDecimal, }) => {
    return (0, utils_1.bn)(amount).minus((0, utils_1.bn)(amount).times(slippageTolerancePercentageDecimal));
};
exports.deductSlippageFromAmount = deductSlippageFromAmount;
const getValuesFromQuoteResponse = ({ buyAsset, sellAsset, response, affiliateBps, }) => {
    const { sellAmount: sellAmountAfterFeesCryptoBaseUnit, feeAmount: feeAmountInSellTokenCryptoBaseUnit, buyAmount, } = response.quote;
    // Remove affiliate fees off the buyAmount to get the amount after affiliate fees, but before slippage bips
    const buyAmountAfterAffiliateFeesCryptoBaseUnit = (0, exports.deductAffiliateFeesFromAmount)({
        amount: buyAmount,
        affiliateBps,
    });
    const buyAmountAfterAffiliateFeesCryptoPrecision = (0, utils_1.fromBaseUnit)(buyAmountAfterAffiliateFeesCryptoBaseUnit, buyAsset.precision);
    const sellAmountCryptoPrecision = (0, utils_1.fromBaseUnit)(sellAmountAfterFeesCryptoBaseUnit, sellAsset.precision);
    const rate = (0, utils_1.bnOrZero)(buyAmountAfterAffiliateFeesCryptoPrecision)
        .div(sellAmountCryptoPrecision)
        .toString();
    const sellAmountBeforeFeesCryptoBaseUnit = (0, utils_1.bnOrZero)(sellAmountAfterFeesCryptoBaseUnit)
        .plus(feeAmountInSellTokenCryptoBaseUnit)
        .toFixed();
    const buyAmountBeforeAffiliateAndProtocolFeesCryptoBaseUnit = (0, utils_1.convertPrecision)({
        value: sellAmountBeforeFeesCryptoBaseUnit,
        inputExponent: sellAsset.precision,
        outputExponent: buyAsset.precision,
    })
        .times(rate)
        .toFixed(0);
    return {
        rate,
        buyAmountBeforeFeesCryptoBaseUnit: buyAmountBeforeAffiliateAndProtocolFeesCryptoBaseUnit,
        buyAmountAfterFeesCryptoBaseUnit: buyAmountAfterAffiliateFeesCryptoBaseUnit,
    };
};
exports.getValuesFromQuoteResponse = getValuesFromQuoteResponse;
const generateAppDataFromDoc = async (doc) => {
    const appData = await (0, app_data_1.stringifyDeterministic)(doc);
    const appDataKeccak256 = (0, viem_1.keccak256)((0, viem_1.stringToBytes)(appData));
    return { fullAppData: appData, appDataKeccak256 };
};
const metadataApi = new app_data_1.MetadataApi();
// See https://api.cow.fi/docs/#/default/post_api_v1_quote / https://github.com/cowprotocol/app-data
const getFullAppData = async (slippageTolerancePercentage, affiliateAppDataFragment, orderClass1) => {
    const APP_CODE = 'shapeshift';
    const orderClass = { orderClass: orderClass1 };
    const quote = {
        slippageBips: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentage).toString(),
    };
    const appDataDoc = await metadataApi.generateAppDataDoc({
        appCode: APP_CODE,
        metadata: {
            quote,
            orderClass,
            ...affiliateAppDataFragment,
        },
    });
    const { fullAppData, appDataKeccak256 } = await generateAppDataFromDoc(appDataDoc);
    return { appDataHash: appDataKeccak256, appData: fullAppData };
};
exports.getFullAppData = getFullAppData;
const getAffiliateAppDataFragmentByChainId = ({ affiliateBps, chainId, }) => {
    const hasAffiliateFee = (0, utils_1.bnOrZero)(affiliateBps).gt(0);
    if (!hasAffiliateFee)
        return {};
    return {
        partnerFee: {
            bps: Number(affiliateBps),
            recipient: (0, helpers_1.getTreasuryAddressFromChainId)(chainId),
        },
    };
};
exports.getAffiliateAppDataFragmentByChainId = getAffiliateAppDataFragmentByChainId;
