"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain-V1 Unchained API
 * Provides access to thorchain-v1 chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxHistoryAllOfToJSON = exports.TxHistoryAllOfFromJSONTyped = exports.TxHistoryAllOfFromJSON = exports.instanceOfTxHistoryAllOf = void 0;
const Tx_1 = require("./Tx");
/**
 * Check if a given object implements the TxHistoryAllOf interface.
 */
function instanceOfTxHistoryAllOf(value) {
    let isInstance = true;
    isInstance = isInstance && "txs" in value;
    return isInstance;
}
exports.instanceOfTxHistoryAllOf = instanceOfTxHistoryAllOf;
function TxHistoryAllOfFromJSON(json) {
    return TxHistoryAllOfFromJSONTyped(json, false);
}
exports.TxHistoryAllOfFromJSON = TxHistoryAllOfFromJSON;
function TxHistoryAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txs': (json['txs'].map(Tx_1.TxFromJSON)),
    };
}
exports.TxHistoryAllOfFromJSONTyped = TxHistoryAllOfFromJSONTyped;
function TxHistoryAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txs': (value.txs.map(Tx_1.TxToJSON)),
    };
}
exports.TxHistoryAllOfToJSON = TxHistoryAllOfToJSON;
