"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompressedNftMetadataToJSON = exports.CompressedNftMetadataFromJSONTyped = exports.CompressedNftMetadataFromJSON = exports.instanceOfCompressedNftMetadata = void 0;
const CompressedNftCreator_1 = require("./CompressedNftCreator");
const CompressedNftMetadataCollection_1 = require("./CompressedNftMetadataCollection");
const TokenStandard_1 = require("./TokenStandard");
/**
 * Check if a given object implements the CompressedNftMetadata interface.
 */
function instanceOfCompressedNftMetadata(value) {
    let isInstance = true;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "creators" in value;
    isInstance = isInstance && "isMutable" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "primarySaleHappened" in value;
    isInstance = isInstance && "sellerFeeBasisPoints" in value;
    isInstance = isInstance && "symbol" in value;
    isInstance = isInstance && "tokenProgramVersion" in value;
    isInstance = isInstance && "tokenStandard" in value;
    isInstance = isInstance && "uri" in value;
    return isInstance;
}
exports.instanceOfCompressedNftMetadata = instanceOfCompressedNftMetadata;
function CompressedNftMetadataFromJSON(json) {
    return CompressedNftMetadataFromJSONTyped(json, false);
}
exports.CompressedNftMetadataFromJSON = CompressedNftMetadataFromJSON;
function CompressedNftMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'collection': (0, CompressedNftMetadataCollection_1.CompressedNftMetadataCollectionFromJSON)(json['collection']),
        'creators': (json['creators'].map(CompressedNftCreator_1.CompressedNftCreatorFromJSON)),
        'isMutable': json['isMutable'],
        'name': json['name'],
        'primarySaleHappened': json['primarySaleHappened'],
        'sellerFeeBasisPoints': json['sellerFeeBasisPoints'],
        'symbol': json['symbol'],
        'tokenProgramVersion': json['tokenProgramVersion'],
        'tokenStandard': (0, TokenStandard_1.TokenStandardFromJSON)(json['tokenStandard']),
        'uri': json['uri'],
    };
}
exports.CompressedNftMetadataFromJSONTyped = CompressedNftMetadataFromJSONTyped;
function CompressedNftMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'collection': (0, CompressedNftMetadataCollection_1.CompressedNftMetadataCollectionToJSON)(value.collection),
        'creators': (value.creators.map(CompressedNftCreator_1.CompressedNftCreatorToJSON)),
        'isMutable': value.isMutable,
        'name': value.name,
        'primarySaleHappened': value.primarySaleHappened,
        'sellerFeeBasisPoints': value.sellerFeeBasisPoints,
        'symbol': value.symbol,
        'tokenProgramVersion': value.tokenProgramVersion,
        'tokenStandard': (0, TokenStandard_1.TokenStandardToJSON)(value.tokenStandard),
        'uri': value.uri,
    };
}
exports.CompressedNftMetadataToJSON = CompressedNftMetadataToJSON;
