"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompressedNftCreatorToJSON = exports.CompressedNftCreatorFromJSONTyped = exports.CompressedNftCreatorFromJSON = exports.instanceOfCompressedNftCreator = void 0;
/**
 * Check if a given object implements the CompressedNftCreator interface.
 */
function instanceOfCompressedNftCreator(value) {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "share" in value;
    isInstance = isInstance && "verified" in value;
    return isInstance;
}
exports.instanceOfCompressedNftCreator = instanceOfCompressedNftCreator;
function CompressedNftCreatorFromJSON(json) {
    return CompressedNftCreatorFromJSONTyped(json, false);
}
exports.CompressedNftCreatorFromJSON = CompressedNftCreatorFromJSON;
function CompressedNftCreatorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address': json['address'],
        'share': json['share'],
        'verified': json['verified'],
    };
}
exports.CompressedNftCreatorFromJSONTyped = CompressedNftCreatorFromJSONTyped;
function CompressedNftCreatorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address': value.address,
        'share': value.share,
        'verified': value.verified,
    };
}
exports.CompressedNftCreatorToJSON = CompressedNftCreatorToJSON;
