"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSupportedChainId = exports.zrxTokenToAssetId = exports.assetIdToZrxToken = exports.baseUrlFromChainId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const viem_1 = require("viem");
const types_2 = require("../../types");
const constants_1 = require("../constants");
const baseUrlFromChainId = (zrxBaseUrl, chainId) => {
    switch (chainId) {
        case types_1.KnownChainIds.EthereumMainnet:
            return `${zrxBaseUrl}ethereum/`;
        case types_1.KnownChainIds.AvalancheMainnet:
            return `${zrxBaseUrl}avalanche/`;
        case types_1.KnownChainIds.OptimismMainnet:
            return `${zrxBaseUrl}optimism/`;
        case types_1.KnownChainIds.BnbSmartChainMainnet:
            return `${zrxBaseUrl}bnbsmartchain/`;
        case types_1.KnownChainIds.PolygonMainnet:
            return `${zrxBaseUrl}polygon/`;
        case types_1.KnownChainIds.ArbitrumMainnet:
            return `${zrxBaseUrl}arbitrum/`;
        case types_1.KnownChainIds.BaseMainnet:
            return `${zrxBaseUrl}base/`;
        default:
            (0, utils_1.assertUnreachable)(chainId);
    }
};
exports.baseUrlFromChainId = baseUrlFromChainId;
// converts an asset to zrx token (symbol or contract address)
const assetIdToZrxToken = (assetId) => {
    const { assetReference, assetNamespace } = (0, caip_1.fromAssetId)(assetId);
    return assetNamespace === 'slip44' ? constants_1.ZRX_NATIVE_ASSET_ADDRESS : assetReference;
};
exports.assetIdToZrxToken = assetIdToZrxToken;
const zrxTokenToAssetId = (token, chainId) => {
    const isDefaultAddress = (0, viem_1.getAddress)(token) === constants_1.ZRX_NATIVE_ASSET_ADDRESS;
    const { assetReference, assetNamespace } = (() => {
        if (!isDefaultAddress)
            return {
                assetReference: token,
                assetNamespace: chainId === types_1.KnownChainIds.BnbSmartChainMainnet
                    ? caip_1.ASSET_NAMESPACE.bep20
                    : caip_1.ASSET_NAMESPACE.erc20,
            };
        switch (chainId) {
            case types_1.KnownChainIds.EthereumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Ethereum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.AvalancheMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.AvalancheC,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.OptimismMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Optimism,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.BnbSmartChainMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.BnbSmartChain,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.PolygonMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Polygon,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.ArbitrumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Arbitrum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case types_1.KnownChainIds.BaseMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Base,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            default:
                throw Error(`chainId '${chainId}' not supported`);
        }
    })();
    return (0, caip_1.toAssetId)({
        chainId,
        assetNamespace,
        assetReference,
    });
};
exports.zrxTokenToAssetId = zrxTokenToAssetId;
const isSupportedChainId = (chainId) => {
    return types_2.zrxSupportedChainIds.includes(chainId);
};
exports.isSupportedChainId = isSupportedChainId;
