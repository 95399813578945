"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lifiSwapper = exports.LIFI_GET_TRADE_QUOTE_POLLING_INTERVAL = exports.LIFI_TRADE_POLL_INTERVAL_MILLISECONDS = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("../../utils");
const filterAssetIdsBySellable_1 = require("../utils/filterAssetIdsBySellable/filterAssetIdsBySellable");
const filterBuyAssetsBySellAssetId_1 = require("../utils/filterBuyAssetsBySellAssetId/filterBuyAssetsBySellAssetId");
exports.LIFI_TRADE_POLL_INTERVAL_MILLISECONDS = 30000;
exports.LIFI_GET_TRADE_QUOTE_POLLING_INTERVAL = 60000;
exports.lifiSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve((0, filterAssetIdsBySellable_1.filterEvmAssetIdsBySellable)(assets).map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve([
            ...(0, filterBuyAssetsBySellAssetId_1.filterCrossChainEvmBuyAssetsBySellAssetId)(input),
            // TODO(gomes): This is weird but a temporary product compromise to accommodate for the fact that OP rewards have weird heuristics
            // and would detect same-chain swaps on Li.Fi as cross-chain swaps, making the rewards gameable by same-chain swaps
            // Remove me when OP rewards ends
            ...(0, filterBuyAssetsBySellAssetId_1.filterSameChainEvmBuyAssetsBySellAssetId)(input).filter(asset => asset.chainId !== caip_1.optimismChainId),
        ].map(asset => asset.assetId));
    },
};
