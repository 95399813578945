"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain Unchained API
 * Provides access to thorchain chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DelegationToJSON = exports.DelegationFromJSONTyped = exports.DelegationFromJSON = exports.instanceOfDelegation = void 0;
const Validator_1 = require("./Validator");
const Value_1 = require("./Value");
/**
 * Check if a given object implements the Delegation interface.
 */
function instanceOfDelegation(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "shares" in value;
    isInstance = isInstance && "validator" in value;
    return isInstance;
}
exports.instanceOfDelegation = instanceOfDelegation;
function DelegationFromJSON(json) {
    return DelegationFromJSONTyped(json, false);
}
exports.DelegationFromJSON = DelegationFromJSON;
function DelegationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': (0, Value_1.ValueFromJSON)(json['balance']),
        'shares': json['shares'],
        'validator': (0, Validator_1.ValidatorFromJSON)(json['validator']),
    };
}
exports.DelegationFromJSONTyped = DelegationFromJSONTyped;
function DelegationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': (0, Value_1.ValueToJSON)(value.balance),
        'shares': value.shares,
        'validator': (0, Validator_1.ValidatorToJSON)(value.validator),
    };
}
exports.DelegationToJSON = DelegationToJSON;
