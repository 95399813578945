"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainflipNetwork = void 0;
var ChainflipNetwork;
(function (ChainflipNetwork) {
    ChainflipNetwork["Bitcoin"] = "Bitcoin";
    ChainflipNetwork["Ethereum"] = "Ethereum";
    ChainflipNetwork["Arbitrum"] = "Arbitrum";
    ChainflipNetwork["Solana"] = "Solana";
})(ChainflipNetwork || (exports.ChainflipNetwork = ChainflipNetwork = {}));
