"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThresholdedAffiliateBps = exports.getExpectedAffiliateFeeSellAssetThorUnit = exports.getOutboundFeeInSellAssetThorBaseUnit = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const constants_1 = require("../../constants");
const constants_2 = require("../constants");
const isRune_1 = require("../isRune/isRune");
const poolAssetHelpers_1 = require("../poolAssetHelpers/poolAssetHelpers");
const thorService_1 = require("../thorService");
const getOutboundFeeInSellAssetThorBaseUnit = (runePerAsset) => {
    return (0, utils_1.bn)(constants_1.THORCHAIN_OUTBOUND_FEE_RUNE_THOR_UNIT).dividedBy(runePerAsset);
};
exports.getOutboundFeeInSellAssetThorBaseUnit = getOutboundFeeInSellAssetThorBaseUnit;
const getExpectedAffiliateFeeSellAssetThorUnit = (sellAmountCryptoBaseUnit, sellAsset, affiliateBps) => {
    const sellAmountThorUnit = (0, utils_1.convertPrecision)({
        value: sellAmountCryptoBaseUnit,
        inputExponent: sellAsset.precision,
        outputExponent: constants_2.THORCHAIN_FIXED_PRECISION,
    });
    const affiliatePercent = (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps);
    return sellAmountThorUnit.times(affiliatePercent);
};
exports.getExpectedAffiliateFeeSellAssetThorUnit = getExpectedAffiliateFeeSellAssetThorUnit;
// don't apply an affiliate fee if it's below the outbound fee for the inbound pool
const getThresholdedAffiliateBps = async ({ sellAsset, affiliateBps, sellAmountCryptoBaseUnit, config, }) => {
    const outboundFeeSellAssetThorUnit = await (async () => {
        if ((0, isRune_1.isRune)(sellAsset.assetId))
            return constants_1.THORCHAIN_OUTBOUND_FEE_RUNE_THOR_UNIT;
        const midgardUrl = config.REACT_APP_MIDGARD_URL;
        const sellPoolId = (0, poolAssetHelpers_1.assetIdToPoolAssetId)({ assetId: sellAsset.assetId });
        // get pool data for the sell asset
        const poolResult = await thorService_1.thorService.get(`${midgardUrl}/pool/${sellPoolId}`);
        if (poolResult.isErr())
            throw poolResult.unwrapErr();
        const pool = poolResult.unwrap().data;
        // calculate the rune outbound fee denominated in the sell asset, in thor units
        return (0, exports.getOutboundFeeInSellAssetThorBaseUnit)(pool.assetPrice);
    })();
    // calculate the expected affiliate fee, in thor units
    const expectedAffiliateFeeSellAssetThorUnit = (0, exports.getExpectedAffiliateFeeSellAssetThorUnit)(sellAmountCryptoBaseUnit, sellAsset, affiliateBps);
    const isAffiliateFeeBelowOutboundFee = expectedAffiliateFeeSellAssetThorUnit.lte(outboundFeeSellAssetThorUnit);
    return isAffiliateFeeBelowOutboundFee ? '0' : affiliateBps;
};
exports.getThresholdedAffiliateBps = getThresholdedAffiliateBps;
