"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lifiTokenToAssetId = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const constants_1 = require("../constants");
const lifiTokenToAssetId = (lifiToken) => {
    const chainReference = lifiToken.chainId.toString();
    const chainId = (0, caip_1.toChainId)({
        chainNamespace: caip_1.CHAIN_NAMESPACE.Evm,
        chainReference,
    });
    const isDefaultAddress = lifiToken.address === constants_1.DEFAULT_LIFI_TOKEN_ADDRESS;
    const { assetReference, assetNamespace } = (() => {
        if (!isDefaultAddress)
            return {
                assetReference: lifiToken.address,
                assetNamespace: chainReference === caip_1.CHAIN_REFERENCE.BnbSmartChainMainnet
                    ? caip_1.ASSET_NAMESPACE.bep20
                    : caip_1.ASSET_NAMESPACE.erc20,
            };
        switch (chainReference) {
            case caip_1.CHAIN_REFERENCE.EthereumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Ethereum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.AvalancheCChain:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.AvalancheC,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.OptimismMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Optimism,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.BnbSmartChainMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.BnbSmartChain,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.PolygonMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Polygon,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.GnosisMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Gnosis,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.ArbitrumMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Arbitrum,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            case caip_1.CHAIN_REFERENCE.BaseMainnet:
                return {
                    assetReference: caip_1.ASSET_REFERENCE.Base,
                    assetNamespace: caip_1.ASSET_NAMESPACE.slip44,
                };
            default:
                throw Error(`chainId '${lifiToken.chainId}' not supported`);
        }
    })();
    return (0, caip_1.toAssetId)({
        chainId,
        assetNamespace,
        assetReference,
    });
};
exports.lifiTokenToAssetId = lifiTokenToAssetId;
