"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/ethereum-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxToJSON = exports.TxFromJSONTyped = exports.TxFromJSON = exports.instanceOfTx = void 0;
const runtime_1 = require("../runtime");
const InternalTx_1 = require("./InternalTx");
const TokenTransfer_1 = require("./TokenTransfer");
/**
 * Check if a given object implements the Tx interface.
 */
function instanceOfTx(value) {
    let isInstance = true;
    isInstance = isInstance && "txid" in value;
    isInstance = isInstance && "blockHeight" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "confirmations" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "gasLimit" in value;
    isInstance = isInstance && "gasPrice" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
exports.instanceOfTx = instanceOfTx;
function TxFromJSON(json) {
    return TxFromJSONTyped(json, false);
}
exports.TxFromJSON = TxFromJSON;
function TxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txid': json['txid'],
        'blockHash': !(0, runtime_1.exists)(json, 'blockHash') ? undefined : json['blockHash'],
        'blockHeight': json['blockHeight'],
        'timestamp': json['timestamp'],
        'from': json['from'],
        'to': json['to'],
        'confirmations': json['confirmations'],
        'value': json['value'],
        'fee': json['fee'],
        'gasLimit': json['gasLimit'],
        'gasUsed': !(0, runtime_1.exists)(json, 'gasUsed') ? undefined : json['gasUsed'],
        'gasPrice': json['gasPrice'],
        'status': json['status'],
        'inputData': !(0, runtime_1.exists)(json, 'inputData') ? undefined : json['inputData'],
        'tokenTransfers': !(0, runtime_1.exists)(json, 'tokenTransfers') ? undefined : (json['tokenTransfers'].map(TokenTransfer_1.TokenTransferFromJSON)),
        'internalTxs': !(0, runtime_1.exists)(json, 'internalTxs') ? undefined : (json['internalTxs'].map(InternalTx_1.InternalTxFromJSON)),
    };
}
exports.TxFromJSONTyped = TxFromJSONTyped;
function TxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txid': value.txid,
        'blockHash': value.blockHash,
        'blockHeight': value.blockHeight,
        'timestamp': value.timestamp,
        'from': value.from,
        'to': value.to,
        'confirmations': value.confirmations,
        'value': value.value,
        'fee': value.fee,
        'gasLimit': value.gasLimit,
        'gasUsed': value.gasUsed,
        'gasPrice': value.gasPrice,
        'status': value.status,
        'inputData': value.inputData,
        'tokenTransfers': value.tokenTransfers === undefined ? undefined : (value.tokenTransfers.map(TokenTransfer_1.TokenTransferToJSON)),
        'internalTxs': value.internalTxs === undefined ? undefined : (value.internalTxs.map(InternalTx_1.InternalTxToJSON)),
    };
}
exports.TxToJSON = TxToJSON;
