"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.L1_ORBIT_CUSTOM_GATEWAY_ABI = void 0;
exports.L1_ORBIT_CUSTOM_GATEWAY_ABI = [
    {
        inputs: [
            { internalType: 'uint256', name: 'dataLength', type: 'uint256' },
            { internalType: 'uint256', name: 'maxDataLength', type: 'uint256' },
        ],
        name: 'DataTooLarge',
        type: 'error',
    },
    { inputs: [], name: 'GasLimitTooLarge', type: 'error' },
    {
        inputs: [
            { internalType: 'uint256', name: 'expected', type: 'uint256' },
            { internalType: 'uint256', name: 'actual', type: 'uint256' },
        ],
        name: 'InsufficientSubmissionCost',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'expected', type: 'uint256' },
            { internalType: 'uint256', name: 'actual', type: 'uint256' },
        ],
        name: 'InsufficientValue',
        type: 'error',
    },
    { inputs: [], name: 'L1Forked', type: 'error' },
    {
        inputs: [{ internalType: 'address', name: 'origin', type: 'address' }],
        name: 'NotAllowedOrigin',
        type: 'error',
    },
    { inputs: [], name: 'NotForked', type: 'error' },
    { inputs: [], name: 'NotOrigin', type: 'error' },
    {
        inputs: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'address', name: 'owner', type: 'address' },
        ],
        name: 'NotOwner',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'address', name: 'rollup', type: 'address' },
            { internalType: 'address', name: 'owner', type: 'address' },
        ],
        name: 'NotRollupOrOwner',
        type: 'error',
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'l2CallValue', type: 'uint256' },
            { internalType: 'uint256', name: 'deposit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxSubmissionCost', type: 'uint256' },
            { internalType: 'address', name: 'excessFeeRefundAddress', type: 'address' },
            { internalType: 'address', name: 'callValueRefundAddress', type: 'address' },
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'RetryableData',
        type: 'error',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'user', type: 'address' },
            { indexed: false, internalType: 'bool', name: 'val', type: 'bool' },
        ],
        name: 'AllowListAddressSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'bool', name: 'isEnabled', type: 'bool' }],
        name: 'AllowListEnabledUpdated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'uint256', name: 'messageNum', type: 'uint256' },
            { indexed: false, internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'InboxMessageDelivered',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: true, internalType: 'uint256', name: 'messageNum', type: 'uint256' }],
        name: 'InboxMessageDeliveredFromOrigin',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
        name: 'Paused',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
        name: 'Unpaused',
        type: 'event',
    },
    {
        inputs: [],
        name: 'allowListEnabled',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'bridge',
        outputs: [{ internalType: 'contract IBridge', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'dataLength', type: 'uint256' },
            { internalType: 'uint256', name: 'baseFee', type: 'uint256' },
        ],
        name: 'calculateRetryableSubmissionFee',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'l2CallValue', type: 'uint256' },
            { internalType: 'uint256', name: 'maxSubmissionCost', type: 'uint256' },
            { internalType: 'address', name: 'excessFeeRefundAddress', type: 'address' },
            { internalType: 'address', name: 'callValueRefundAddress', type: 'address' },
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'createRetryableTicket',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'l2CallValue', type: 'uint256' },
            { internalType: 'uint256', name: 'maxSubmissionCost', type: 'uint256' },
            { internalType: 'address', name: 'excessFeeRefundAddress', type: 'address' },
            { internalType: 'address', name: 'callValueRefundAddress', type: 'address' },
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'createRetryableTicketNoRefundAliasRewrite',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'depositEth',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'depositEth',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'contract IBridge', name: '_bridge', type: 'address' },
            { internalType: 'contract ISequencerInbox', name: '_sequencerInbox', type: 'address' },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'isAllowed',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
        inputs: [],
        name: 'paused',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'contract IBridge', name: '', type: 'address' }],
        name: 'postUpgradeInit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendContractTransaction',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendL1FundedContractTransaction',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendL1FundedUnsignedTransaction',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendL1FundedUnsignedTransactionToFork',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes', name: 'messageData', type: 'bytes' }],
        name: 'sendL2Message',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes', name: 'messageData', type: 'bytes' }],
        name: 'sendL2MessageFromOrigin',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendUnsignedTransaction',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'sendUnsignedTransactionToFork',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'address', name: 'withdrawTo', type: 'address' },
        ],
        name: 'sendWithdrawEthToFork',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'sequencerInbox',
        outputs: [{ internalType: 'contract ISequencerInbox', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address[]', name: 'user', type: 'address[]' },
            { internalType: 'bool[]', name: 'val', type: 'bool[]' },
        ],
        name: 'setAllowList',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bool', name: '_allowListEnabled', type: 'bool' }],
        name: 'setAllowListEnabled',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'l2CallValue', type: 'uint256' },
            { internalType: 'uint256', name: 'maxSubmissionCost', type: 'uint256' },
            { internalType: 'address', name: 'excessFeeRefundAddress', type: 'address' },
            { internalType: 'address', name: 'callValueRefundAddress', type: 'address' },
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'uniswapCreateRetryableTicket',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
    { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'l2CallValue', type: 'uint256' },
            { internalType: 'uint256', name: 'maxSubmissionCost', type: 'uint256' },
            { internalType: 'address', name: 'excessFeeRefundAddress', type: 'address' },
            { internalType: 'address', name: 'callValueRefundAddress', type: 'address' },
            { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
            { internalType: 'uint256', name: 'maxFeePerGas', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'unsafeCreateRetryableTicket',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
    },
];
