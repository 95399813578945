"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portalsSwapper = void 0;
const utils_1 = require("../../utils");
const constants_1 = require("./constants");
exports.portalsSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve(assets
            .filter(asset => constants_1.PORTALS_SUPPORTED_CHAIN_IDS.sell.includes(asset.chainId))
            .map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve(input.assets
            .filter(asset => constants_1.PORTALS_SUPPORTED_CHAIN_IDS.buy.includes(asset.chainId))
            .map(asset => asset.assetId));
    },
};
