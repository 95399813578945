"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEvmTxFees = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const constants_1 = require("../../constants");
const getEvmTxFees = async (args) => {
    const { adapter, supportsEIP1559 } = args;
    const { average } = await adapter.getGasFeeData();
    const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
        ...average,
        supportsEIP1559,
        gasLimit: constants_1.THOR_EVM_GAS_LIMIT, // hardcoded default for quote estimation (no wallet)
    });
    return { networkFeeCryptoBaseUnit };
};
exports.getEvmTxFees = getEvmTxFees;
