"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLifiEvmAssetAddress = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const helpers_1 = require("../../../utils/helpers/helpers");
const constants_1 = require("../constants");
const getLifiEvmAssetAddress = (asset) => {
    if ((0, helpers_1.isNativeEvmAsset)(asset.assetId))
        return constants_1.DEFAULT_LIFI_TOKEN_ADDRESS;
    const { assetReference } = (0, caip_1.fromAssetId)(asset.assetId);
    return (0, caip_1.isAssetReference)(assetReference) ? constants_1.DEFAULT_LIFI_TOKEN_ADDRESS : assetReference;
};
exports.getLifiEvmAssetAddress = getLifiEvmAssetAddress;
