"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
class Parser {
    async parse(tx) {
        if (!tx.memo?.startsWith('rFOX reward'))
            return;
        const match = tx.memo.match(/Staking Address: (?<stakingAddress>\w+)\) - Epoch #(?<epoch>\d+) \(IPFS Hash: (?<ipfsHash>\w+)\)/);
        if (!match?.groups)
            return;
        const { epoch, stakingAddress, ipfsHash } = match.groups;
        if (!epoch || !stakingAddress || !ipfsHash)
            return;
        return await Promise.resolve({
            data: {
                parser: 'rfox',
                type: 'thorchain',
                method: 'reward',
                epoch: parseInt(match.groups.epoch, 10),
                stakingAddress: match.groups.stakingAddress,
                ipfsHash: match.groups.ipfsHash,
            },
        });
    }
}
exports.Parser = Parser;
