"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenToJSON = exports.TokenFromJSONTyped = exports.TokenFromJSON = exports.instanceOfToken = void 0;
/**
 * Check if a given object implements the Token interface.
 */
function instanceOfToken(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "decimals" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "symbol" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
exports.instanceOfToken = instanceOfToken;
function TokenFromJSON(json) {
    return TokenFromJSONTyped(json, false);
}
exports.TokenFromJSON = TokenFromJSON;
function TokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'decimals': json['decimals'],
        'name': json['name'],
        'symbol': json['symbol'],
        'type': json['type'],
    };
}
exports.TokenFromJSONTyped = TokenFromJSONTyped;
function TokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'decimals': value.decimals,
        'name': value.name,
        'symbol': value.symbol,
        'type': value.type,
    };
}
exports.TokenToJSON = TokenToJSON;
