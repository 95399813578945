"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformLifiStepFeeData = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const constants_1 = require("../constants");
const lifiChainIdToChainId_1 = require("../lifiChainIdtoChainId/lifiChainIdToChainId");
const lifiTokenToAssetId_1 = require("../lifiTokenToAssetId/lifiTokenToAssetId");
const transformLifiStepFeeData = ({ chainId, lifiStep, assets, }) => {
    if (!(0, chain_adapters_1.isEvmChainId)(chainId)) {
        throw Error("chainId isn't an EVM ChainId", {
            cause: { chainId },
        });
    }
    // aggregate fee costs by asset id and whether they are included or not
    const feeCosts = (lifiStep.estimate.feeCosts ?? []).reduce((acc, feeCost) => {
        const { amount, token, included, name } = feeCost;
        const assetId = (0, lifiTokenToAssetId_1.lifiTokenToAssetId)(token);
        if (!acc[assetId]) {
            acc[assetId] = {
                token,
                included: BigInt(0),
                notIncluded: BigInt(0),
            };
        }
        // Ensures we don't double-count the affiliate fee both as affiliate fee *and* as protocol fees
        if (name === constants_1.LIFI_SHARED_FEES_STEP_NAME) {
            return acc;
        }
        included
            ? (acc[assetId].included += BigInt(amount))
            : (acc[assetId].notIncluded += BigInt(amount));
        return acc;
    }, {});
    const protocolFees = Object.entries(feeCosts).reduce((acc, [assetId, feeCost]) => {
        const { included, notIncluded, token } = feeCost;
        const asset = assets[assetId];
        const { amountCryptoBaseUnit, requiresBalance } = (() => {
            // prioritize protocol fees that require balance to ensure the necessary balance checks are performed downstream
            if (notIncluded > 0n) {
                return { amountCryptoBaseUnit: notIncluded.toString(), requiresBalance: true };
            }
            return { amountCryptoBaseUnit: included.toString(), requiresBalance: false };
        })();
        acc[assetId] = {
            amountCryptoBaseUnit,
            asset: {
                chainId: asset?.chainId ?? (0, lifiChainIdToChainId_1.lifiChainIdToChainId)(token.chainId),
                precision: asset?.precision ?? token.decimals,
                symbol: asset?.symbol ?? token.symbol,
                ...asset,
            },
            requiresBalance,
        };
        return acc;
    }, {});
    return protocolFees;
};
exports.transformLifiStepFeeData = transformLifiStepFeeData;
