"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardToJSON = exports.RewardFromJSONTyped = exports.RewardFromJSON = exports.instanceOfReward = void 0;
const Validator_1 = require("./Validator");
const Value_1 = require("./Value");
/**
 * Check if a given object implements the Reward interface.
 */
function instanceOfReward(value) {
    let isInstance = true;
    isInstance = isInstance && "rewards" in value;
    isInstance = isInstance && "validator" in value;
    return isInstance;
}
exports.instanceOfReward = instanceOfReward;
function RewardFromJSON(json) {
    return RewardFromJSONTyped(json, false);
}
exports.RewardFromJSON = RewardFromJSON;
function RewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rewards': (json['rewards'].map(Value_1.ValueFromJSON)),
        'validator': (0, Validator_1.ValidatorFromJSON)(json['validator']),
    };
}
exports.RewardFromJSONTyped = RewardFromJSONTyped;
function RewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rewards': (value.rewards.map(Value_1.ValueToJSON)),
        'validator': (0, Validator_1.ValidatorToJSON)(value.validator),
    };
}
exports.RewardToJSON = RewardToJSON;
