"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorsAllOfToJSON = exports.ValidatorsAllOfFromJSONTyped = exports.ValidatorsAllOfFromJSON = exports.instanceOfValidatorsAllOf = void 0;
const Validator_1 = require("./Validator");
/**
 * Check if a given object implements the ValidatorsAllOf interface.
 */
function instanceOfValidatorsAllOf(value) {
    let isInstance = true;
    isInstance = isInstance && "validators" in value;
    return isInstance;
}
exports.instanceOfValidatorsAllOf = instanceOfValidatorsAllOf;
function ValidatorsAllOfFromJSON(json) {
    return ValidatorsAllOfFromJSONTyped(json, false);
}
exports.ValidatorsAllOfFromJSON = ValidatorsAllOfFromJSON;
function ValidatorsAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'validators': (json['validators'].map(Validator_1.ValidatorFromJSON)),
    };
}
exports.ValidatorsAllOfFromJSONTyped = ValidatorsAllOfFromJSONTyped;
function ValidatorsAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'validators': (value.validators.map(Validator_1.ValidatorToJSON)),
    };
}
exports.ValidatorsAllOfToJSON = ValidatorsAllOfToJSON;
