"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedelegationToJSON = exports.RedelegationFromJSONTyped = exports.RedelegationFromJSON = exports.instanceOfRedelegation = void 0;
const RedelegationEntry_1 = require("./RedelegationEntry");
const Validator_1 = require("./Validator");
/**
 * Check if a given object implements the Redelegation interface.
 */
function instanceOfRedelegation(value) {
    let isInstance = true;
    isInstance = isInstance && "destinationValidator" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "sourceValidator" in value;
    return isInstance;
}
exports.instanceOfRedelegation = instanceOfRedelegation;
function RedelegationFromJSON(json) {
    return RedelegationFromJSONTyped(json, false);
}
exports.RedelegationFromJSON = RedelegationFromJSON;
function RedelegationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'destinationValidator': (0, Validator_1.ValidatorFromJSON)(json['destinationValidator']),
        'entries': (json['entries'].map(RedelegationEntry_1.RedelegationEntryFromJSON)),
        'sourceValidator': (0, Validator_1.ValidatorFromJSON)(json['sourceValidator']),
    };
}
exports.RedelegationFromJSONTyped = RedelegationFromJSONTyped;
function RedelegationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'destinationValidator': (0, Validator_1.ValidatorToJSON)(value.destinationValidator),
        'entries': (value.entries.map(RedelegationEntry_1.RedelegationEntryToJSON)),
        'sourceValidator': (0, Validator_1.ValidatorToJSON)(value.sourceValidator),
    };
}
exports.RedelegationToJSON = RedelegationToJSON;
