"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThorTxInfo = void 0;
const thorchain_utils_1 = require("../../../../thorchain-utils");
const getThorTxInfo = async ({ sellAsset, xpub, memo, config, }) => {
    const daemonUrl = config.REACT_APP_THORCHAIN_NODE_URL;
    const maybeInboundAddress = await (0, thorchain_utils_1.getInboundAddressDataForChain)(daemonUrl, sellAsset.assetId, false);
    if (maybeInboundAddress.isErr())
        throw maybeInboundAddress.unwrapErr();
    const inboundAddress = maybeInboundAddress.unwrap();
    const vault = inboundAddress.address;
    return {
        opReturnData: memo,
        vault,
        pubkey: xpub,
    };
};
exports.getThorTxInfo = getThorTxInfo;
