"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompressedNftMetadataCollectionToJSON = exports.CompressedNftMetadataCollectionFromJSONTyped = exports.CompressedNftMetadataCollectionFromJSON = exports.instanceOfCompressedNftMetadataCollection = void 0;
/**
 * Check if a given object implements the CompressedNftMetadataCollection interface.
 */
function instanceOfCompressedNftMetadataCollection(value) {
    let isInstance = true;
    isInstance = isInstance && "verified" in value;
    isInstance = isInstance && "key" in value;
    return isInstance;
}
exports.instanceOfCompressedNftMetadataCollection = instanceOfCompressedNftMetadataCollection;
function CompressedNftMetadataCollectionFromJSON(json) {
    return CompressedNftMetadataCollectionFromJSONTyped(json, false);
}
exports.CompressedNftMetadataCollectionFromJSON = CompressedNftMetadataCollectionFromJSON;
function CompressedNftMetadataCollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'verified': json['verified'],
        'key': json['key'],
    };
}
exports.CompressedNftMetadataCollectionFromJSONTyped = CompressedNftMetadataCollectionFromJSONTyped;
function CompressedNftMetadataCollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'verified': value.verified,
        'key': value.key,
    };
}
exports.CompressedNftMetadataCollectionToJSON = CompressedNftMetadataCollectionToJSON;
