"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CosmosSdkMessageType = exports.ThorchainMessageType = void 0;
var ThorchainMessageType;
(function (ThorchainMessageType) {
    ThorchainMessageType["MsgDeposit"] = "thorchain/MsgDeposit";
    ThorchainMessageType["MsgSend"] = "thorchain/MsgSend";
})(ThorchainMessageType || (exports.ThorchainMessageType = ThorchainMessageType = {}));
var CosmosSdkMessageType;
(function (CosmosSdkMessageType) {
    CosmosSdkMessageType["MsgBeginRedelegate"] = "cosmos-sdk/MsgBeginRedelegate";
    CosmosSdkMessageType["MsgDelegate"] = "cosmos-sdk/MsgDelegate";
    CosmosSdkMessageType["MsgSend"] = "cosmos-sdk/MsgSend";
    CosmosSdkMessageType["MsgUndelegate"] = "cosmos-sdk/MsgUndelegate";
    CosmosSdkMessageType["MsgWithdrawDelegationReward"] = "cosmos-sdk/MsgWithdrawDelegationReward";
})(CosmosSdkMessageType || (exports.CosmosSdkMessageType = CosmosSdkMessageType = {}));
