"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUtxoTxFees = void 0;
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const getUtxoTxFees = async ({ opReturnData, vault, sellAmountCryptoBaseUnit, sellAdapter, pubkey, protocolFees, }) => {
    const getFeeDataInput = {
        to: vault,
        value: sellAmountCryptoBaseUnit,
        chainSpecific: { pubkey, opReturnData },
    };
    const feeDataOptions = await sellAdapter.getFeeData(getFeeDataInput);
    const feeData = feeDataOptions['fast'];
    // BCH specific hack:
    // For some reason when sats per byte comes back as 1 (which is very common for bch)
    // broadcast will fail because it thinks the intrinsic fee is too low
    // it feels like possibly an off by-a-few-bytes bug with how we are using coinselect with opReturnData
    // Bumping BCH fees here resolves this for now until we have time to find a better solution
    const isFromBch = sellAdapter.getChainId() === types_1.KnownChainIds.BitcoinCashMainnet;
    const feeMultiplier = isFromBch ? (0, utils_1.bn)(2) : (0, utils_1.bn)(1);
    const networkFee = feeMultiplier.times(feeData.txFee).dp(0).toString();
    const satsPerByte = feeMultiplier.times(feeData.chainSpecific.satoshiPerByte).dp(0).toString();
    return {
        networkFeeCryptoBaseUnit: networkFee,
        protocolFees,
        chainSpecific: {
            satsPerByte,
            byteCount: (0, utils_1.bn)(feeData.txFee).dividedBy(feeData.chainSpecific.satoshiPerByte).dp(0).toString(),
        },
    };
};
exports.getUtxoTxFees = getUtxoTxFees;
