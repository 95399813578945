"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceToJSON = exports.SourceFromJSONTyped = exports.SourceFromJSON = exports.Source = void 0;
/**
 *
 * @export
 */
exports.Source = {
    FormFunction: 'FORM_FUNCTION',
    ExchangeArt: 'EXCHANGE_ART',
    CandyMachineV3: 'CANDY_MACHINE_V3',
    CandyMachineV2: 'CANDY_MACHINE_V2',
    CandyMachineV1: 'CANDY_MACHINE_V1',
    Unknown: 'UNKNOWN',
    Solanart: 'SOLANART',
    Solsea: 'SOLSEA',
    MagicEden: 'MAGIC_EDEN',
    Holaplex: 'HOLAPLEX',
    Metaplex: 'METAPLEX',
    Opensea: 'OPENSEA',
    SolanaProgramLibrary: 'SOLANA_PROGRAM_LIBRARY',
    Anchor: 'ANCHOR',
    Phantom: 'PHANTOM',
    SystemProgram: 'SYSTEM_PROGRAM',
    StakeProgram: 'STAKE_PROGRAM',
    Coinbase: 'COINBASE',
    CoralCube: 'CORAL_CUBE',
    Hedge: 'HEDGE',
    LaunchMyNft: 'LAUNCH_MY_NFT',
    GemBank: 'GEM_BANK',
    GemFarm: 'GEM_FARM',
    Degods: 'DEGODS',
    BlocksmithLabs: 'BLOCKSMITH_LABS',
    Yawww: 'YAWWW',
    Atadia: 'ATADIA',
    DigitalEyes: 'DIGITAL_EYES',
    Hyperspace: 'HYPERSPACE',
    Tensor: 'TENSOR',
    Bifrost: 'BIFROST',
    Jupiter: 'JUPITER',
    MercurialStableSwap: 'MERCURIAL_STABLE_SWAP',
    Saber: 'SABER',
    Serum: 'SERUM',
    StepFinance: 'STEP_FINANCE',
    Cropper: 'CROPPER',
    Raydium: 'RAYDIUM',
    Aldrin: 'ALDRIN',
    Crema: 'CREMA',
    Lifinity: 'LIFINITY',
    Cykura: 'CYKURA',
    Orca: 'ORCA',
    Marinade: 'MARINADE',
    Stepn: 'STEPN',
    SenchaExchange: 'SENCHA_EXCHANGE',
    Saros: 'SAROS',
    EnglishAuction: 'ENGLISH_AUCTION',
    Foxy: 'FOXY',
    Hadeswap: 'HADESWAP',
    FoxyStaking: 'FOXY_STAKING',
    FoxyRaffle: 'FOXY_RAFFLE',
    FoxyTokenMarket: 'FOXY_TOKEN_MARKET',
    FoxyMissions: 'FOXY_MISSIONS',
    FoxyMarmalade: 'FOXY_MARMALADE',
    FoxyCoinflip: 'FOXY_COINFLIP',
    FoxyAuction: 'FOXY_AUCTION',
    Citrus: 'CITRUS',
    Zeta: 'ZETA',
    Elixir: 'ELIXIR',
    ElixirLaunchpad: 'ELIXIR_LAUNCHPAD',
    CardinalRent: 'CARDINAL_RENT',
    CardinalStaking: 'CARDINAL_STAKING',
    BpfLoader: 'BPF_LOADER',
    BpfUpgradeableLoader: 'BPF_UPGRADEABLE_LOADER',
    Squads: 'SQUADS',
    SharkyFi: 'SHARKY_FI',
    OpenCreatorProtocol: 'OPEN_CREATOR_PROTOCOL',
    Bubblegum: 'BUBBLEGUM',
    WSol: 'W_SOL',
    Dust: 'DUST',
    Soli: 'SOLI',
    Usdc: 'USDC',
    Flwr: 'FLWR',
    Hdg: 'HDG',
    Mean: 'MEAN',
    Uxd: 'UXD',
    Shdw: 'SHDW',
    Polis: 'POLIS',
    Atlas: 'ATLAS',
    Ush: 'USH',
    Trtls: 'TRTLS',
    Runner: 'RUNNER',
    Invictus: 'INVICTUS'
};
function SourceFromJSON(json) {
    return SourceFromJSONTyped(json, false);
}
exports.SourceFromJSON = SourceFromJSON;
function SourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SourceFromJSONTyped = SourceFromJSONTyped;
function SourceToJSON(value) {
    return value;
}
exports.SourceToJSON = SourceToJSON;
