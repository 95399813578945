"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrichedTransactionTransactionErrorToJSON = exports.EnrichedTransactionTransactionErrorFromJSONTyped = exports.EnrichedTransactionTransactionErrorFromJSON = exports.instanceOfEnrichedTransactionTransactionError = void 0;
/**
 * Check if a given object implements the EnrichedTransactionTransactionError interface.
 */
function instanceOfEnrichedTransactionTransactionError(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfEnrichedTransactionTransactionError = instanceOfEnrichedTransactionTransactionError;
function EnrichedTransactionTransactionErrorFromJSON(json) {
    return EnrichedTransactionTransactionErrorFromJSONTyped(json, false);
}
exports.EnrichedTransactionTransactionErrorFromJSON = EnrichedTransactionTransactionErrorFromJSON;
function EnrichedTransactionTransactionErrorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EnrichedTransactionTransactionErrorFromJSONTyped = EnrichedTransactionTransactionErrorFromJSONTyped;
function EnrichedTransactionTransactionErrorToJSON(value) {
    return value;
}
exports.EnrichedTransactionTransactionErrorToJSON = EnrichedTransactionTransactionErrorToJSON;
