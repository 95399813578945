"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Cosmos Unchained API
 * Provides access to cosmos chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoAllOfToJSON = exports.InfoAllOfFromJSONTyped = exports.InfoAllOfFromJSON = exports.instanceOfInfoAllOf = void 0;
/**
 * Check if a given object implements the InfoAllOf interface.
 */
function instanceOfInfoAllOf(value) {
    let isInstance = true;
    isInstance = isInstance && "annualProvisions" in value;
    isInstance = isInstance && "apr" in value;
    isInstance = isInstance && "bondedTokens" in value;
    isInstance = isInstance && "communityTax" in value;
    isInstance = isInstance && "totalSupply" in value;
    return isInstance;
}
exports.instanceOfInfoAllOf = instanceOfInfoAllOf;
function InfoAllOfFromJSON(json) {
    return InfoAllOfFromJSONTyped(json, false);
}
exports.InfoAllOfFromJSON = InfoAllOfFromJSON;
function InfoAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'annualProvisions': json['annualProvisions'],
        'apr': json['apr'],
        'bondedTokens': json['bondedTokens'],
        'communityTax': json['communityTax'],
        'totalSupply': json['totalSupply'],
    };
}
exports.InfoAllOfFromJSONTyped = InfoAllOfFromJSONTyped;
function InfoAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'annualProvisions': value.annualProvisions,
        'apr': value.apr,
        'bondedTokens': value.bondedTokens,
        'communityTax': value.communityTax,
        'totalSupply': value.totalSupply,
    };
}
exports.InfoAllOfToJSON = InfoAllOfToJSON;
