"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain Unchained API
 * Provides access to thorchain chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedelegationEntryToJSON = exports.RedelegationEntryFromJSONTyped = exports.RedelegationEntryFromJSON = exports.instanceOfRedelegationEntry = void 0;
/**
 * Check if a given object implements the RedelegationEntry interface.
 */
function instanceOfRedelegationEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "completionTime" in value;
    isInstance = isInstance && "shares" in value;
    return isInstance;
}
exports.instanceOfRedelegationEntry = instanceOfRedelegationEntry;
function RedelegationEntryFromJSON(json) {
    return RedelegationEntryFromJSONTyped(json, false);
}
exports.RedelegationEntryFromJSON = RedelegationEntryFromJSON;
function RedelegationEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'balance': json['balance'],
        'completionTime': json['completionTime'],
        'shares': json['shares'],
    };
}
exports.RedelegationEntryFromJSONTyped = RedelegationEntryFromJSONTyped;
function RedelegationEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'balance': value.balance,
        'completionTime': value.completionTime,
        'shares': value.shares,
    };
}
exports.RedelegationEntryToJSON = RedelegationEntryToJSON;
