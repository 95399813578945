"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionContextToJSON = exports.TransactionContextFromJSONTyped = exports.TransactionContextFromJSON = exports.TransactionContext = void 0;
/**
 *
 * @export
 */
exports.TransactionContext = {
    Auction: 'AUCTION',
    InstantSale: 'INSTANT_SALE',
    Offer: 'OFFER',
    GlobalOffer: 'GLOBAL_OFFER',
    Mint: 'MINT',
    Unknown: 'UNKNOWN'
};
function TransactionContextFromJSON(json) {
    return TransactionContextFromJSONTyped(json, false);
}
exports.TransactionContextFromJSON = TransactionContextFromJSON;
function TransactionContextFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionContextFromJSONTyped = TransactionContextFromJSONTyped;
function TransactionContextToJSON(value) {
    return value;
}
exports.TransactionContextToJSON = TransactionContextToJSON;
