"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountDataToJSON = exports.AccountDataFromJSONTyped = exports.AccountDataFromJSON = exports.instanceOfAccountData = void 0;
const TokenBalanceChange_1 = require("./TokenBalanceChange");
/**
 * Check if a given object implements the AccountData interface.
 */
function instanceOfAccountData(value) {
    let isInstance = true;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "nativeBalanceChange" in value;
    isInstance = isInstance && "tokenBalanceChanges" in value;
    return isInstance;
}
exports.instanceOfAccountData = instanceOfAccountData;
function AccountDataFromJSON(json) {
    return AccountDataFromJSONTyped(json, false);
}
exports.AccountDataFromJSON = AccountDataFromJSON;
function AccountDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'account': json['account'],
        'nativeBalanceChange': json['nativeBalanceChange'],
        'tokenBalanceChanges': (json['tokenBalanceChanges'] === null ? null : json['tokenBalanceChanges'].map(TokenBalanceChange_1.TokenBalanceChangeFromJSON)),
    };
}
exports.AccountDataFromJSONTyped = AccountDataFromJSONTyped;
function AccountDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'account': value.account,
        'nativeBalanceChange': value.nativeBalanceChange,
        'tokenBalanceChanges': (value.tokenBalanceChanges === null ? null : value.tokenBalanceChanges.map(TokenBalanceChange_1.TokenBalanceChangeToJSON)),
    };
}
exports.AccountDataToJSON = AccountDataToJSON;
