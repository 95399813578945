"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEvmTxFees = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const constants_1 = require("../../ThorchainSwapper/utils/constants");
const getEvmTxFees = async (args) => {
    const { adapter, supportsEIP1559 } = args;
    const { average } = await adapter.getGasFeeData();
    // That's not THOR but this should do the trick - overestimated effectively
    const gasLimit = constants_1.THOR_EVM_GAS_LIMIT;
    return chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
        ...average,
        supportsEIP1559,
        gasLimit,
    });
};
exports.getEvmTxFees = getEvmTxFees;
