"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/litecoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTxVoutInnerScriptPubKeyToJSON = exports.RawTxVoutInnerScriptPubKeyFromJSONTyped = exports.RawTxVoutInnerScriptPubKeyFromJSON = exports.instanceOfRawTxVoutInnerScriptPubKey = void 0;
/**
 * Check if a given object implements the RawTxVoutInnerScriptPubKey interface.
 */
function instanceOfRawTxVoutInnerScriptPubKey(value) {
    let isInstance = true;
    isInstance = isInstance && "addresses" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reqSigs" in value;
    isInstance = isInstance && "hex" in value;
    isInstance = isInstance && "asm" in value;
    return isInstance;
}
exports.instanceOfRawTxVoutInnerScriptPubKey = instanceOfRawTxVoutInnerScriptPubKey;
function RawTxVoutInnerScriptPubKeyFromJSON(json) {
    return RawTxVoutInnerScriptPubKeyFromJSONTyped(json, false);
}
exports.RawTxVoutInnerScriptPubKeyFromJSON = RawTxVoutInnerScriptPubKeyFromJSON;
function RawTxVoutInnerScriptPubKeyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addresses': json['addresses'],
        'type': json['type'],
        'reqSigs': json['reqSigs'],
        'hex': json['hex'],
        'asm': json['asm'],
    };
}
exports.RawTxVoutInnerScriptPubKeyFromJSONTyped = RawTxVoutInnerScriptPubKeyFromJSONTyped;
function RawTxVoutInnerScriptPubKeyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addresses': value.addresses,
        'type': value.type,
        'reqSigs': value.reqSigs,
        'hex': value.hex,
        'asm': value.asm,
    };
}
exports.RawTxVoutInnerScriptPubKeyToJSON = RawTxVoutInnerScriptPubKeyToJSON;
