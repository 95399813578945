"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoin-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoutToJSON = exports.VoutFromJSONTyped = exports.VoutFromJSON = exports.instanceOfVout = void 0;
const runtime_1 = require("../runtime");
const VinScriptSig_1 = require("./VinScriptSig");
/**
 * Check if a given object implements the Vout interface.
 */
function instanceOfVout(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "n" in value;
    isInstance = isInstance && "scriptPubKey" in value;
    return isInstance;
}
exports.instanceOfVout = instanceOfVout;
function VoutFromJSON(json) {
    return VoutFromJSONTyped(json, false);
}
exports.VoutFromJSON = VoutFromJSON;
function VoutFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': json['value'],
        'n': json['n'],
        'opReturn': !(0, runtime_1.exists)(json, 'opReturn') ? undefined : json['opReturn'],
        'scriptPubKey': (0, VinScriptSig_1.VinScriptSigFromJSON)(json['scriptPubKey']),
        'addresses': !(0, runtime_1.exists)(json, 'addresses') ? undefined : json['addresses'],
    };
}
exports.VoutFromJSONTyped = VoutFromJSONTyped;
function VoutToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'n': value.n,
        'opReturn': value.opReturn,
        'scriptPubKey': (0, VinScriptSig_1.VinScriptSigToJSON)(value.scriptPubKey),
        'addresses': value.addresses,
    };
}
exports.VoutToJSON = VoutToJSON;
