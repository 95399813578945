"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTEventToJSON = exports.NFTEventFromJSONTyped = exports.NFTEventFromJSON = exports.instanceOfNFTEvent = void 0;
const runtime_1 = require("../runtime");
const Source_1 = require("./Source");
const Token_1 = require("./Token");
const TransactionContext_1 = require("./TransactionContext");
const TransactionType_1 = require("./TransactionType");
/**
 * Check if a given object implements the NFTEvent interface.
 */
function instanceOfNFTEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "seller" in value;
    isInstance = isInstance && "buyer" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "nfts" in value;
    return isInstance;
}
exports.instanceOfNFTEvent = instanceOfNFTEvent;
function NFTEventFromJSON(json) {
    return NFTEventFromJSONTyped(json, false);
}
exports.NFTEventFromJSON = NFTEventFromJSON;
function NFTEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'seller': json['seller'],
        'buyer': json['buyer'],
        'timestamp': json['timestamp'],
        'amount': json['amount'],
        'fee': json['fee'],
        'signature': json['signature'],
        'source': (0, Source_1.SourceFromJSON)(json['source']),
        'type': (0, TransactionType_1.TransactionTypeFromJSON)(json['type']),
        'saleType': !(0, runtime_1.exists)(json, 'saleType') ? undefined : (0, TransactionContext_1.TransactionContextFromJSON)(json['saleType']),
        'nfts': (json['nfts'].map(Token_1.TokenFromJSON)),
    };
}
exports.NFTEventFromJSONTyped = NFTEventFromJSONTyped;
function NFTEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seller': value.seller,
        'buyer': value.buyer,
        'timestamp': value.timestamp,
        'amount': value.amount,
        'fee': value.fee,
        'signature': value.signature,
        'source': (0, Source_1.SourceToJSON)(value.source),
        'type': (0, TransactionType_1.TransactionTypeToJSON)(value.type),
        'saleType': (0, TransactionContext_1.TransactionContextToJSON)(value.saleType),
        'nfts': (value.nfts.map(Token_1.TokenToJSON)),
    };
}
exports.NFTEventToJSON = NFTEventToJSON;
