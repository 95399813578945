"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addBasisPointAmount = exports.subtractBasisPointAmount = exports.convertPercentageToBasisPoints = exports.convertBasisPointsToPercentage = exports.convertDecimalPercentageToBasisPoints = exports.convertBasisPointsToDecimalPercentage = void 0;
const bignumber_1 = require("./bignumber/bignumber");
const convertBasisPointsToDecimalPercentage = (basisPoints) => (0, bignumber_1.bnOrZero)(basisPoints).div(10000);
exports.convertBasisPointsToDecimalPercentage = convertBasisPointsToDecimalPercentage;
const convertDecimalPercentageToBasisPoints = (decimalPercentage) => (0, bignumber_1.bnOrZero)(decimalPercentage).times(10000);
exports.convertDecimalPercentageToBasisPoints = convertDecimalPercentageToBasisPoints;
const convertBasisPointsToPercentage = (basisPoints) => (0, bignumber_1.bnOrZero)(basisPoints).div(100);
exports.convertBasisPointsToPercentage = convertBasisPointsToPercentage;
const convertPercentageToBasisPoints = (percentage) => (0, bignumber_1.bnOrZero)(percentage).times(100);
exports.convertPercentageToBasisPoints = convertPercentageToBasisPoints;
/**
 * Subtracts basis point amount from a given value.
 *
 * @param value The value to subtract basis points from.
 * @param basisPoints The number of basis points to subtract.
 * @param roundingMode
 * @returns The new number that is the input value minus the basis points of the value.
 */
const subtractBasisPointAmount = (value, basisPoints, roundingMode) => {
    const bigNumValue = (0, bignumber_1.bn)(value);
    // Basis point is 1/100th of a percent
    const percentValue = (0, exports.convertBasisPointsToDecimalPercentage)(basisPoints);
    const subtractValue = bigNumValue.times(percentValue);
    // Subtract basis points from the original value
    const resultValue = bigNumValue.minus(subtractValue);
    return roundingMode !== undefined ? resultValue.toFixed(0, roundingMode) : resultValue.toFixed();
};
exports.subtractBasisPointAmount = subtractBasisPointAmount;
/**
 * Adds basis point amount from a given value.
 *
 * @param value The value to subtract basis points from.
 * @param basisPoints The number of basis points to subtract.
 * @param roundingMode
 * @returns The new number that is the input value minus the basis points of the value.
 */
const addBasisPointAmount = (value, basisPoints, roundingMode) => {
    const bigNumValue = (0, bignumber_1.bn)(value);
    // Basis point is 1/100th of a percent
    const percentValue = (0, exports.convertBasisPointsToDecimalPercentage)(basisPoints);
    const addValue = bigNumValue.times(percentValue);
    // Subtract basis points from the original value
    const resultValue = bigNumValue.plus(addValue);
    return roundingMode !== undefined ? resultValue.toFixed(0, roundingMode) : resultValue.toFixed();
};
exports.addBasisPointAmount = addBasisPointAmount;
