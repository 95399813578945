"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.depositWithExpiry = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const viem_1 = require("viem");
const depositWithExpiry = ({ vault, asset, amount, memo, expiry, }) => {
    const data = (0, viem_1.encodeFunctionData)({
        abi: contracts_1.THORCHAIN_ROUTER_ABI,
        functionName: 'depositWithExpiry',
        args: [vault, asset, amount, memo, expiry],
    });
    return data;
};
exports.depositWithExpiry = depositWithExpiry;
