"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSome = void 0;
const lodash_1 = require("lodash");
__exportStar(require("./makeAsset/makeAsset"), exports);
__exportStar(require("./assertUnreachable"), exports);
__exportStar(require("./sha256"), exports);
__exportStar(require("./chainIdToFeeAsset"), exports);
__exportStar(require("./chainIdToFeeAssetId"), exports);
__exportStar(require("./utxo"), exports);
__exportStar(require("./bignumber/bignumber"), exports);
__exportStar(require("./basisPoints"), exports);
__exportStar(require("./baseUnits/baseUnits"), exports);
__exportStar(require("./promises"), exports);
__exportStar(require("./treasury"), exports);
__exportStar(require("./timeout"), exports);
__exportStar(require("./createThrottle"), exports);
__exportStar(require("./evmChainIds"), exports);
const isSome = (option) => !(0, lodash_1.isUndefined)(option) && !(0, lodash_1.isNull)(option);
exports.isSome = isSome;
