"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionErrorToJSON = exports.TransactionErrorFromJSONTyped = exports.TransactionErrorFromJSON = exports.instanceOfTransactionError = void 0;
/**
 * Check if a given object implements the TransactionError interface.
 */
function instanceOfTransactionError(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfTransactionError = instanceOfTransactionError;
function TransactionErrorFromJSON(json) {
    return TransactionErrorFromJSONTyped(json, false);
}
exports.TransactionErrorFromJSON = TransactionErrorFromJSON;
function TransactionErrorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionErrorFromJSONTyped = TransactionErrorFromJSONTyped;
function TransactionErrorToJSON(value) {
    return value;
}
exports.TransactionErrorToJSON = TransactionErrorToJSON;
