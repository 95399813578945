"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchArbitrumBridgeQuote = exports.fetchArbitrumBridgePrice = void 0;
const sdk_1 = require("@arbitrum/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const contracts_1 = require("@shapeshiftoss/contracts");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const ethers5_1 = require("ethers5");
const chains_1 = require("viem/chains");
const types_2 = require("../types");
const helpers_1 = require("./helpers");
const fetchArbitrumBridgeSwap = async ({ chainId, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAsset, sendAddress, receiveAddress, supportsEIP1559, assertGetEvmChainAdapter, quoteOrRate, }) => {
    if (quoteOrRate === 'quote' && !receiveAddress)
        throw new Error('receiveAddress is required for Arbitrum Bridge quotes');
    if (quoteOrRate === 'quote' && !sendAddress)
        throw new Error('sendAddress is required for Arbitrum Bridge quotes');
    const adapter = assertGetEvmChainAdapter(chainId);
    const l2Network = await (0, sdk_1.getArbitrumNetwork)(chains_1.arbitrum.id);
    const isDeposit = sellAsset.chainId === caip_1.ethChainId;
    const isEthBridge = isDeposit ? sellAsset.assetId === caip_1.ethAssetId : buyAsset.assetId === caip_1.ethAssetId;
    const bridgeType = (() => {
        if (isDeposit) {
            return isEthBridge ? types_2.BRIDGE_TYPE.ETH_DEPOSIT : types_2.BRIDGE_TYPE.ERC20_DEPOSIT;
        }
        return isEthBridge ? types_2.BRIDGE_TYPE.ETH_WITHDRAWAL : types_2.BRIDGE_TYPE.ERC20_WITHDRAWAL;
    })();
    const parentProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.EthereumMainnet);
    const childProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.ArbitrumMainnet);
    switch (bridgeType) {
        case types_2.BRIDGE_TYPE.ETH_DEPOSIT: {
            const bridger = new sdk_1.EthBridger(l2Network);
            const maybeRequest = quoteOrRate === 'quote'
                ? await bridger
                    .getDepositToRequest({
                    parentProvider,
                    childProvider,
                    amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                    from: sendAddress,
                    destinationAddress: receiveAddress,
                })
                    .catch(e => {
                    console.error('Error getting ETH deposit request', e);
                    return undefined;
                })
                : undefined;
            const networkFeeCryptoBaseUnit = await (0, helpers_1.getNetworkFeeOrFallbackCryptoBaseUnit)({
                maybeRequest,
                bridgeType,
                supportsEIP1559,
                adapter,
            });
            return { request: maybeRequest, networkFeeCryptoBaseUnit, allowanceContract: '0x0' };
        }
        case types_2.BRIDGE_TYPE.ETH_WITHDRAWAL: {
            const bridger = new sdk_1.EthBridger(l2Network);
            const maybeRequest = quoteOrRate === 'quote'
                ? await bridger
                    .getWithdrawalRequest({
                    amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                    from: sendAddress,
                    destinationAddress: receiveAddress,
                })
                    .catch(e => {
                    console.error('Error getting ETH withdraw request', e);
                    return undefined;
                })
                : undefined;
            const networkFeeCryptoBaseUnit = await (0, helpers_1.getNetworkFeeOrFallbackCryptoBaseUnit)({
                maybeRequest,
                bridgeType,
                supportsEIP1559,
                adapter,
            });
            return { request: maybeRequest, networkFeeCryptoBaseUnit, allowanceContract: '0x0' };
        }
        case types_2.BRIDGE_TYPE.ERC20_DEPOSIT: {
            const bridger = new sdk_1.Erc20Bridger(l2Network);
            const erc20ParentAddress = (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
            const allowanceContract = await bridger.getParentGatewayAddress(erc20ParentAddress, parentProvider);
            const maybeRequest = quoteOrRate === 'quote'
                ? await bridger
                    .getDepositRequest({
                    amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                    parentProvider,
                    childProvider,
                    erc20ParentAddress,
                    from: sendAddress,
                    destinationAddress: receiveAddress,
                    retryableGasOverrides: {
                        // https://github.com/OffchainLabs/arbitrum-token-bridge/blob/d17c88ef3eef3f4ffc61a04d34d50406039f045d/packages/arb-token-bridge-ui/src/util/TokenDepositUtils.ts#L159
                        // the gas limit may vary by about 20k due to SSTORE (zero vs nonzero)
                        // the 30% gas limit increase should cover the difference
                        gasLimit: { percentIncrease: ethers5_1.BigNumber.from(30) },
                    },
                })
                    .catch(e => {
                    console.error('Error getting ERC20 deposit request', e);
                    return undefined;
                })
                : undefined;
            const networkFeeCryptoBaseUnit = await (0, helpers_1.getNetworkFeeOrFallbackCryptoBaseUnit)({
                maybeRequest,
                bridgeType,
                supportsEIP1559,
                adapter,
            });
            return { request: maybeRequest, networkFeeCryptoBaseUnit, allowanceContract };
        }
        case types_2.BRIDGE_TYPE.ERC20_WITHDRAWAL: {
            const bridger = new sdk_1.Erc20Bridger(l2Network);
            const erc20ParentAddress = (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
            const maybeRequest = quoteOrRate === 'quote'
                ? await bridger
                    .getWithdrawalRequest({
                    amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                    erc20ParentAddress,
                    from: sendAddress,
                    destinationAddress: receiveAddress,
                })
                    .catch(e => {
                    console.error('Error getting ERC20 withdraw request', e);
                    return undefined;
                })
                : undefined;
            const networkFeeCryptoBaseUnit = await (0, helpers_1.getNetworkFeeOrFallbackCryptoBaseUnit)({
                maybeRequest,
                bridgeType,
                supportsEIP1559,
                adapter,
            });
            return { request: maybeRequest, networkFeeCryptoBaseUnit, allowanceContract: '0x0' };
        }
        default:
            (0, utils_1.assertUnreachable)(bridgeType);
    }
};
const fetchArbitrumBridgePrice = (args) => fetchArbitrumBridgeSwap({ ...args, quoteOrRate: 'rate' });
exports.fetchArbitrumBridgePrice = fetchArbitrumBridgePrice;
const fetchArbitrumBridgeQuote = (args) => fetchArbitrumBridgeSwap({ ...args, quoteOrRate: 'quote' });
exports.fetchArbitrumBridgeQuote = fetchArbitrumBridgeQuote;
