"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLifiChainMap = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const createLifiChainMap = (lifiChains) => {
    return new Map(lifiChains.map(({ id, key }) => {
        const chainReference = (() => {
            const maybeChainReference = id.toString();
            if (!(0, caip_1.isChainReference)(maybeChainReference))
                throw Error('invalid chainId', {
                    cause: { chainId: maybeChainReference },
                });
            return maybeChainReference;
        })();
        const chainId = (0, caip_1.toChainId)({
            chainNamespace: caip_1.CHAIN_NAMESPACE.Evm,
            chainReference,
        });
        return [chainId, key];
    }));
};
exports.createLifiChainMap = createLifiChainMap;
