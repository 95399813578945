"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/bitcoincash-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTxToJSON = exports.RawTxFromJSONTyped = exports.RawTxFromJSON = exports.instanceOfRawTx = void 0;
const RawTxVinInner_1 = require("./RawTxVinInner");
const RawTxVoutInner_1 = require("./RawTxVoutInner");
/**
 * Check if a given object implements the RawTx interface.
 */
function instanceOfRawTx(value) {
    let isInstance = true;
    isInstance = isInstance && "txid" in value;
    isInstance = isInstance && "hash" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "vsize" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "locktime" in value;
    isInstance = isInstance && "vin" in value;
    isInstance = isInstance && "vout" in value;
    isInstance = isInstance && "hex" in value;
    isInstance = isInstance && "blockhash" in value;
    isInstance = isInstance && "confirmations" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "blocktime" in value;
    return isInstance;
}
exports.instanceOfRawTx = instanceOfRawTx;
function RawTxFromJSON(json) {
    return RawTxFromJSONTyped(json, false);
}
exports.RawTxFromJSON = RawTxFromJSON;
function RawTxFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'txid': json['txid'],
        'hash': json['hash'],
        'version': json['version'],
        'size': json['size'],
        'vsize': json['vsize'],
        'weight': json['weight'],
        'locktime': json['locktime'],
        'vin': (json['vin'].map(RawTxVinInner_1.RawTxVinInnerFromJSON)),
        'vout': (json['vout'].map(RawTxVoutInner_1.RawTxVoutInnerFromJSON)),
        'hex': json['hex'],
        'blockhash': json['blockhash'],
        'confirmations': json['confirmations'],
        'time': json['time'],
        'blocktime': json['blocktime'],
    };
}
exports.RawTxFromJSONTyped = RawTxFromJSONTyped;
function RawTxToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'txid': value.txid,
        'hash': value.hash,
        'version': value.version,
        'size': value.size,
        'vsize': value.vsize,
        'weight': value.weight,
        'locktime': value.locktime,
        'vin': (value.vin.map(RawTxVinInner_1.RawTxVinInnerToJSON)),
        'vout': (value.vout.map(RawTxVoutInner_1.RawTxVoutInnerToJSON)),
        'hex': value.hex,
        'blockhash': value.blockhash,
        'confirmations': value.confirmations,
        'time': value.time,
        'blocktime': value.blocktime,
    };
}
exports.RawTxToJSON = RawTxToJSON;
