"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chainIdToPortalsNetwork = exports.PORTALS_SUPPORTED_CHAIN_IDS = void 0;
const types_1 = require("@shapeshiftoss/types");
const types_2 = require("./types");
exports.PORTALS_SUPPORTED_CHAIN_IDS = {
    sell: types_2.PortalsSupportedChainIds,
    buy: types_2.PortalsSupportedChainIds,
};
exports.chainIdToPortalsNetwork = {
    [types_1.KnownChainIds.EthereumMainnet]: 'ethereum',
    [types_1.KnownChainIds.AvalancheMainnet]: 'avalanche',
    [types_1.KnownChainIds.OptimismMainnet]: 'optimism',
    [types_1.KnownChainIds.BnbSmartChainMainnet]: 'bsc',
    [types_1.KnownChainIds.PolygonMainnet]: 'polygon',
    [types_1.KnownChainIds.GnosisMainnet]: 'gnosis',
    [types_1.KnownChainIds.ArbitrumMainnet]: 'arbitrum',
    [types_1.KnownChainIds.BaseMainnet]: 'base',
};
