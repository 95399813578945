"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Thorchain Unchained API
 * Provides access to thorchain chain data.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorToJSON = exports.ValidatorFromJSONTyped = exports.ValidatorFromJSON = exports.instanceOfValidator = void 0;
const ValidatorCommission_1 = require("./ValidatorCommission");
const ValidatorUnbonding_1 = require("./ValidatorUnbonding");
/**
 * Check if a given object implements the Validator interface.
 */
function instanceOfValidator(value) {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "apr" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "jailed" in value;
    isInstance = isInstance && "moniker" in value;
    isInstance = isInstance && "shares" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "tokens" in value;
    isInstance = isInstance && "unbonding" in value;
    isInstance = isInstance && "website" in value;
    return isInstance;
}
exports.instanceOfValidator = instanceOfValidator;
function ValidatorFromJSON(json) {
    return ValidatorFromJSONTyped(json, false);
}
exports.ValidatorFromJSON = ValidatorFromJSON;
function ValidatorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address': json['address'],
        'apr': json['apr'],
        'commission': (0, ValidatorCommission_1.ValidatorCommissionFromJSON)(json['commission']),
        'description': json['description'],
        'jailed': json['jailed'],
        'moniker': json['moniker'],
        'shares': json['shares'],
        'status': json['status'],
        'tokens': json['tokens'],
        'unbonding': (0, ValidatorUnbonding_1.ValidatorUnbondingFromJSON)(json['unbonding']),
        'website': json['website'],
    };
}
exports.ValidatorFromJSONTyped = ValidatorFromJSONTyped;
function ValidatorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address': value.address,
        'apr': value.apr,
        'commission': (0, ValidatorCommission_1.ValidatorCommissionToJSON)(value.commission),
        'description': value.description,
        'jailed': value.jailed,
        'moniker': value.moniker,
        'shares': value.shares,
        'status': value.status,
        'tokens': value.tokens,
        'unbonding': (0, ValidatorUnbonding_1.ValidatorUnbondingToJSON)(value.unbonding),
        'website': value.website,
    };
}
exports.ValidatorToJSON = ValidatorToJSON;
