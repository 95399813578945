"use strict";
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * @shapeshiftoss/solana-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 10.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionTypeToJSON = exports.TransactionTypeFromJSONTyped = exports.TransactionTypeFromJSON = exports.TransactionType = void 0;
/**
 *
 * @export
 */
exports.TransactionType = {
    Any: 'ANY',
    Unknown: 'UNKNOWN',
    NftBid: 'NFT_BID',
    NftGlobalBid: 'NFT_GLOBAL_BID',
    NftGlobalBidCancelled: 'NFT_GLOBAL_BID_CANCELLED',
    NftBidCancelled: 'NFT_BID_CANCELLED',
    NftListing: 'NFT_LISTING',
    NftCancelListing: 'NFT_CANCEL_LISTING',
    NftSale: 'NFT_SALE',
    NftMint: 'NFT_MINT',
    NftAuctionCreated: 'NFT_AUCTION_CREATED',
    NftAuctionUpdated: 'NFT_AUCTION_UPDATED',
    NftAuctionCancelled: 'NFT_AUCTION_CANCELLED',
    NftParticipationReward: 'NFT_PARTICIPATION_REWARD',
    NftMintRejected: 'NFT_MINT_REJECTED',
    CreateStore: 'CREATE_STORE',
    WhitelistCreator: 'WHITELIST_CREATOR',
    AddToWhitelist: 'ADD_TO_WHITELIST',
    RemoveFromWhitelist: 'REMOVE_FROM_WHITELIST',
    AuctionManagerClaimBid: 'AUCTION_MANAGER_CLAIM_BID',
    EmptyPaymentAccount: 'EMPTY_PAYMENT_ACCOUNT',
    UpdatePrimarySaleMetadata: 'UPDATE_PRIMARY_SALE_METADATA',
    AddTokenToVault: 'ADD_TOKEN_TO_VAULT',
    ActivateVault: 'ACTIVATE_VAULT',
    InitVault: 'INIT_VAULT',
    InitBank: 'INIT_BANK',
    InitStake: 'INIT_STAKE',
    MergeStake: 'MERGE_STAKE',
    SplitStake: 'SPLIT_STAKE',
    SetBankFlags: 'SET_BANK_FLAGS',
    SetVaultLock: 'SET_VAULT_LOCK',
    UpdateVaultOwner: 'UPDATE_VAULT_OWNER',
    UpdateBankManager: 'UPDATE_BANK_MANAGER',
    RecordRarityPoints: 'RECORD_RARITY_POINTS',
    AddRaritiesToBank: 'ADD_RARITIES_TO_BANK',
    InitFarm: 'INIT_FARM',
    InitFarmer: 'INIT_FARMER',
    RefreshFarmer: 'REFRESH_FARMER',
    UpdateFarm: 'UPDATE_FARM',
    AuthorizeFunder: 'AUTHORIZE_FUNDER',
    DeauthorizeFunder: 'DEAUTHORIZE_FUNDER',
    FundReward: 'FUND_REWARD',
    CancelReward: 'CANCEL_REWARD',
    LockReward: 'LOCK_REWARD',
    Payout: 'PAYOUT',
    ValidateSafetyDepositBoxV2: 'VALIDATE_SAFETY_DEPOSIT_BOX_V2',
    SetAuthority: 'SET_AUTHORITY',
    InitAuctionManagerV2: 'INIT_AUCTION_MANAGER_V2',
    UpdateExternalPriceAccount: 'UPDATE_EXTERNAL_PRICE_ACCOUNT',
    AuctionHouseCreate: 'AUCTION_HOUSE_CREATE',
    CloseEscrowAccount: 'CLOSE_ESCROW_ACCOUNT',
    Withdraw: 'WITHDRAW',
    Deposit: 'DEPOSIT',
    Transfer: 'TRANSFER',
    Burn: 'BURN',
    BurnNft: 'BURN_NFT',
    PlatformFee: 'PLATFORM_FEE',
    Loan: 'LOAN',
    RescindLoan: 'RESCIND_LOAN',
    OfferLoan: 'OFFER_LOAN',
    CancelOffer: 'CANCEL_OFFER',
    LendForNft: 'LEND_FOR_NFT',
    RequestLoan: 'REQUEST_LOAN',
    CancelLoanRequest: 'CANCEL_LOAN_REQUEST',
    BorrowSolForNft: 'BORROW_SOL_FOR_NFT',
    ClaimNft: 'CLAIM_NFT',
    ReborrowSolForNft: 'REBORROW_SOL_FOR_NFT',
    RepayLoan: 'REPAY_LOAN',
    TakeLoan: 'TAKE_LOAN',
    ForecloseLoan: 'FORECLOSE_LOAN',
    UpdateOffer: 'UPDATE_OFFER',
    AddToPool: 'ADD_TO_POOL',
    RemoveFromPool: 'REMOVE_FROM_POOL',
    ClosePosition: 'CLOSE_POSITION',
    Unlabeled: 'UNLABELED',
    CloseAccount: 'CLOSE_ACCOUNT',
    WithdrawGem: 'WITHDRAW_GEM',
    DepositGem: 'DEPOSIT_GEM',
    StakeToken: 'STAKE_TOKEN',
    UnstakeToken: 'UNSTAKE_TOKEN',
    StakeSol: 'STAKE_SOL',
    UnstakeSol: 'UNSTAKE_SOL',
    ClaimRewards: 'CLAIM_REWARDS',
    BuySubscription: 'BUY_SUBSCRIPTION',
    Swap: 'SWAP',
    InitSwap: 'INIT_SWAP',
    CancelSwap: 'CANCEL_SWAP',
    RejectSwap: 'REJECT_SWAP',
    InitializeAccount: 'INITIALIZE_ACCOUNT',
    TokenMint: 'TOKEN_MINT',
    CreateAppraisal: 'CREATE_APPRAISAL',
    CandyMachineWrap: 'CANDY_MACHINE_WRAP',
    CandyMachineUnwrap: 'CANDY_MACHINE_UNWRAP',
    CandyMachineUpdate: 'CANDY_MACHINE_UPDATE',
    CandyMachineRoute: 'CANDY_MACHINE_ROUTE',
    Fractionalize: 'FRACTIONALIZE',
    DepositFractionalPool: 'DEPOSIT_FRACTIONAL_POOL',
    Fuse: 'FUSE',
    CreateRaffle: 'CREATE_RAFFLE',
    BuyTickets: 'BUY_TICKETS',
    UpdateItem: 'UPDATE_ITEM',
    ListItem: 'LIST_ITEM',
    DelistItem: 'DELIST_ITEM',
    AddItem: 'ADD_ITEM',
    CloseItem: 'CLOSE_ITEM',
    BuyItem: 'BUY_ITEM',
    FillOrder: 'FILL_ORDER',
    UpdateOrder: 'UPDATE_ORDER',
    CreateOrder: 'CREATE_ORDER',
    CloseOrder: 'CLOSE_ORDER',
    CancelOrder: 'CANCEL_ORDER',
    KickItem: 'KICK_ITEM',
    UpgradeFox: 'UPGRADE_FOX',
    UpgradeFoxRequest: 'UPGRADE_FOX_REQUEST',
    LoanFox: 'LOAN_FOX',
    BorrowFox: 'BORROW_FOX',
    SwitchFoxRequest: 'SWITCH_FOX_REQUEST',
    SwitchFox: 'SWITCH_FOX',
    CreateEscrow: 'CREATE_ESCROW',
    AcceptRequestArtist: 'ACCEPT_REQUEST_ARTIST',
    CancelEscrow: 'CANCEL_ESCROW',
    AcceptEscrowArtist: 'ACCEPT_ESCROW_ARTIST',
    AcceptEscrowUser: 'ACCEPT_ESCROW_USER',
    PlaceBet: 'PLACE_BET',
    PlaceSolBet: 'PLACE_SOL_BET',
    CreateBet: 'CREATE_BET',
    InitRent: 'INIT_RENT',
    NftRentListing: 'NFT_RENT_LISTING',
    NftRentCancelListing: 'NFT_RENT_CANCEL_LISTING',
    NftRentUpdateListing: 'NFT_RENT_UPDATE_LISTING',
    NftRentActivate: 'NFT_RENT_ACTIVATE',
    NftRentEnd: 'NFT_RENT_END',
    UpgradeProgramInstruction: 'UPGRADE_PROGRAM_INSTRUCTION',
    FinalizeProgramInstruction: 'FINALIZE_PROGRAM_INSTRUCTION',
    ExecuteTransaction: 'EXECUTE_TRANSACTION',
    ApproveTransaction: 'APPROVE_TRANSACTION',
    ActivateTransaction: 'ACTIVATE_TRANSACTION',
    CreateTransaction: 'CREATE_TRANSACTION',
    CancelTransaction: 'CANCEL_TRANSACTION',
    RejectTransaction: 'REJECT_TRANSACTION',
    AddInstruction: 'ADD_INSTRUCTION',
    CreateMasterEdition: 'CREATE_MASTER_EDITION',
    AttachMetadata: 'ATTACH_METADATA',
    RequestPnftMigration: 'REQUEST_PNFT_MIGRATION',
    StartPnftMigration: 'START_PNFT_MIGRATION',
    MigrateToPnft: 'MIGRATE_TO_PNFT',
    UpdateRaffle: 'UPDATE_RAFFLE',
    CreateMerkleTree: 'CREATE_MERKLE_TREE',
    DelegateMerkleTree: 'DELEGATE_MERKLE_TREE',
    CompressedNftMint: 'COMPRESSED_NFT_MINT',
    CompressedNftTransfer: 'COMPRESSED_NFT_TRANSFER',
    CompressedNftRedeem: 'COMPRESSED_NFT_REDEEM',
    CompressedNftCancelRedeem: 'COMPRESSED_NFT_CANCEL_REDEEM',
    CompressedNftBurn: 'COMPRESSED_NFT_BURN',
    CompressedNftVerifyCreator: 'COMPRESSED_NFT_VERIFY_CREATOR',
    CompressedNftUnverifyCreator: 'COMPRESSED_NFT_UNVERIFY_CREATOR',
    CompressedNftVerifyCollection: 'COMPRESSED_NFT_VERIFY_COLLECTION',
    CompressedNftUnverifyCollection: 'COMPRESSED_NFT_UNVERIFY_COLLECTION',
    CompressedNftSetVerifyCollection: 'COMPRESSED_NFT_SET_VERIFY_COLLECTION',
    DecompressNft: 'DECOMPRESS_NFT',
    CompressNft: 'COMPRESS_NFT',
    CompressedNftDelegate: 'COMPRESSED_NFT_DELEGATE',
    CreatePool: 'CREATE_POOL',
    DistributeCompressionRewards: 'DISTRIBUTE_COMPRESSION_REWARDS',
    ChangeComicState: 'CHANGE_COMIC_STATE',
    UpdateRecordAuthorityData: 'UPDATE_RECORD_AUTHORITY_DATA'
};
function TransactionTypeFromJSON(json) {
    return TransactionTypeFromJSONTyped(json, false);
}
exports.TransactionTypeFromJSON = TransactionTypeFromJSON;
function TransactionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionTypeFromJSONTyped = TransactionTypeFromJSONTyped;
function TransactionTypeToJSON(value) {
    return value;
}
exports.TransactionTypeToJSON = TransactionTypeToJSON;
